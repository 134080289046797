import React, { useContext, useState } from 'react'
import CargandoContext from '../../cargandoContext/CargandoContext';
import { authorizing } from '../../../../utilidades/TextosIdioma';
import DialogoBotones from '../../../general/dialogoBotones/DialogoBotones';
import { traerToken } from '../../../../proxy/LocalStorage';
import { changePasswordUser } from '../../../../actions/sesionActions';
import { ERROR } from '../../../../utilidades/Constantes';

const ChangePassword = ({ cerrar }) => {

    const { idioma, correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)
    let textos = authorizing.changePassword
    let textosDialogoError = authorizing.dialogoErrorContrasenia
    let textosDialogoContrasenaCambiada = authorizing.dialogoContrasenaCambiada
    const token = traerToken()

    const [usuario, setUsuario] = useState({
        new_password1: '',
        new_password2: '',
        old_password: ''

    })
    const [error, setError] = useState(false)
    const [contrasenaCambiada, setContrasenaCambiada] = useState(false)
    const [validaciones, setValidaciones] = useState({
        diferentes: false,
        debil: false
    })
    const handleSubmit = async (e) => {
        e.preventDefault()
        let letterNumber = /^(?=.*\d)(?=.*[a-z]).{6,20}$/;
        if(usuario.new_password1 !== usuario.new_password2){
            setValidaciones({
                ...validaciones,
                diferentes: true
            })
            return;
        }
        if(!usuario.new_password1.match(letterNumber)){
            setValidaciones({
                ...validaciones,
                debil: true
            })
            return;
        }
        correrIndicadorCarga()
        let res = await changePasswordUser(token, usuario)
        quitarIndicadorCarga()
        if(res === ERROR){
            setError(true)
        } else {
            setContrasenaCambiada(true)
        }
    }

    const cerrarError = () => {
        setError(false)
    }

    const handleChange = (e) => {
        setUsuario({...usuario, [e.target.name]: e.target.value})
    }

    return (
        <div className='contenedor-dialogo'>
            { error && <DialogoBotones titulo={textosDialogoError.titulo[idioma]}
                descripcion={textosDialogoError.descripcion[idioma]}
                cerrar={cerrarError} click1={cerrarError}
                boton1={"OK"} />}
            { 
                contrasenaCambiada && <DialogoBotones titulo={textosDialogoContrasenaCambiada.titulo[idioma]}
                    descripcion={textosDialogoContrasenaCambiada.descripcion[idioma]}
                    cerrar={cerrar} click1={cerrar}
                    boton1={"OK"} />
            }
            <h1 className="titulo-dialogo-auth" >
                {textos.titulo[idioma]}
            </h1>
            <form className='change-password' onSubmit={handleSubmit}>
                <div className='email'>
                    <label className='label-email-password'>
                        {textos.current[idioma]}
                    </label>
                    <input required type="password" name="old_password"
                        className='input-email-password'
                        onChange={handleChange}
                        value={usuario.old_password} />
                </div>
                <div className='email'>
                    <label className='label-email-password'>
                        {textos.new[idioma]}
                    </label>
                    <input required type="password" name="new_password1"
                        className='input-email-password'
                        onChange={handleChange}
                        value={usuario.new_password1} />
                </div>
                <div className='email'>
                    <label className='label-email-password'>
                        {textos.confirm[idioma]}
                    </label>
                    <input required type="password" name="new_password2"
                        className='input-email-password'
                        onChange={handleChange}
                        value={usuario.new_password2} />
                </div>
                <div className="contenedor-botones-change">
                    <button id="btn-primario" className="boton-dialogo-change" type='submit'>
                        {textos.ok[idioma]}
                    </button>
                </div>
            </form>
            {(validaciones.debil || validaciones.diferentes) &&
                <div className='texto-rojo error'>
                    {validaciones.diferentes ? textos.sameError[idioma] :
                    textos.badPassword[idioma]}
                </div>
            }
        </div>
    )
};

export default ChangePassword