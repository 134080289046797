import {combineReducers} from 'redux'
import toursReducer from './toursReducer'
import layerReducer from './layerReducer'
import sesionReducer from './sesionReducer'
import pasarelaReducer from './pasarelaReducer'

export default combineReducers({
    toursReducer,
    layerReducer,
    sesionReducer,
    pasarelaReducer
});
