import { createSelector } from "reselect"
import { TRAER_TOURS_CATEGORIES, TREAR_LUGAR_SELECCIONADO, TRAER_LISTA_CLASSES, TRAER_LISTA_CATEGORIAS_LAYERS, TREAR_LISTA_FILTRADA_LUGARES } from "../actions/layerActions"

const INITIAL_STATE = {
    toursCategories: null,
    classes: null,
    lugares: null,
    currentPlace: null,
}

export default (state = INITIAL_STATE, action) =>{
    switch (action.type){
        case TRAER_TOURS_CATEGORIES:
            return { ...state, toursCategories: action.payload }
        case TRAER_LISTA_CLASSES:
            return { ...state, classes: action.payload }
        case TRAER_LISTA_CATEGORIAS_LAYERS:
            let classes = state.classes
            classes[action.idClassModel].layers[action.idLayer].categories = action.payload
            return { ...state, classes }
        case TREAR_LISTA_FILTRADA_LUGARES:
            return {...state, lugares: action.payload}
        case TREAR_LUGAR_SELECCIONADO:
            return {...state, currentPlace: action.payload}
        default: 
            return {...state}
    }
}

export const getToursCategories = createSelector(state => state.layerReducer, layerReducer => layerReducer.toursCategories)

export const getClasses = createSelector(state => state.layerReducer, layerReducer => layerReducer.classes)

export const getLugares = createSelector(state => state.layerReducer, layerReducer => layerReducer.lugares)

export const getCurrentPlace = createSelector(state => state.layerReducer, layerReducer => layerReducer.currentPlace)