import React, { useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import CargandoContext from './screens/general/cargandoContext/CargandoContext'
import './styles/main.sass'
import Footer from './screens/general/footer/Footer'
import Header from './screens/general/header/Header'
import IndicadorCarga from './screens/general/indicadorCarga/IndicadorCarga'
import Contacto from './screens/general/contacto/Contacto'
import DialogoAuth from './screens/general/authorizing/DialogoAuth'
import { traerIdioma, cambiarIdioma } from './proxy/LocalStorage'
import { CuentaConfirmada } from './screens/home/components/cuentaConfirmada/CuentaConfirmada'
// Lazy load different components
const Download = React.lazy(() => import('./screens/help/Help'));
const DetalleTour = React.lazy(() => import('./screens/detalleTour/DetalleTour'));
const Tours = React.lazy(() => import('./screens/tours/Tours'));
const PlaceDetail = React.lazy(() => import('./screens/placeDetail/PlaceDetail'));
const About = React.lazy(() => import('./screens/about/About'));
const UserProfile = React.lazy(() => import('./screens/general/authorizing/components/userProfile/UserProfile'));
const Inicio = React.lazy(() => import('./screens/home/Home'));

const App = () => {

  const [cargando, setCargando] = useState(false)
  const [login, setLogin] = useState(false)
  const [mostrarCuenta, setMostrarCuenta] = useState(false)
  const [idioma, setIdioma] = useState(traerIdioma())

  const confirmarCuenta = (valor) => (setMostrarCuenta(valor))

  return (
    <CargandoContext.Provider value={{
      correrIndicadorCarga: () => setCargando(true),
      quitarIndicadorCarga: () => setCargando(false),
      abrirLogin: () => setLogin(true),
      cambiarIdioma: (idioma) => {
        cambiarIdioma(idioma)
        setIdioma(idioma)
      },
      idioma: idioma
    }}>
      <BrowserRouter>
      {mostrarCuenta && <CuentaConfirmada/>}
        <Header />
        <React.Suspense fallback={<p>Loading</p>}>
          <Switch>
            <Route path="/help" component={Download} />
            <Route path="/tours/:id" component={DetalleTour} />
            <Route path="/tours" component={Tours} />
            <Route path="/places/:id" component={PlaceDetail} />
            <Route path="/places" component={Tours} />
            <Route path="/about" component={About} />
            <Route path="/profile" component={UserProfile} />
            <Route path="/home">
              <Inicio confirmarCuenta={confirmarCuenta}/>
            </Route>
            <Route path="/" component={Tours}/>
          </Switch>
        </React.Suspense>
        <Contacto />
        <Footer />
      </BrowserRouter>
      {
        cargando &&
        <IndicadorCarga />
      }
      {
        login &&
        <DialogoAuth context="login" cerrar={() => setLogin(false)} />
      }
    </CargandoContext.Provider>
  );
}

export default App;