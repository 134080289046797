import { createSelector } from "reselect"
import {
    DELETE_SIGN_UP, DELETE_SIGN_UP_CLIENT, LOG_IN, LOG_OUT,
    SIGN_UP, SIGN_UP_CLIENT, USER_CONSTANT, USER_INFO_CHANGE
} from "../actions/sesionActions"

export const SIN_VERIFICAR = 'SIN_VERIFICAR'

const INITIAL_STATE = {
    uiduser: null,
    user: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOG_IN:
            return { ...state, token: action.payload }
        case LOG_OUT:
            return { ...state, token: null, user: null }
        case SIGN_UP:
            return { ...state, userCreated: action.payload }
        case DELETE_SIGN_UP:
            return { ...state, userCreated: null }
        case SIGN_UP_CLIENT:
            return { ...state, userClient: action.payload }
        case DELETE_SIGN_UP_CLIENT:
            return { ...state, userClient: null }
        case USER_CONSTANT: 
            if (action.payload && !action.payload.user_client)
                return { ...state, user: null }
            const verificado = (action.payload && action.payload.user_client && action.payload.user_client.verified)
            if (verificado)
                return { ...state, user: action.payload }
            else
                return { ...state, user: SIN_VERIFICAR }
        case USER_INFO_CHANGE:
            return {
                ...state,
                user: {
                    ...state.user,
                    ...action.payload.user,
                    user_client: {
                        ...state.user.user_client,
                        ...action.payload.user.user_client
                    }
                }
            }
        default:
            return { ...state }
    }
}

export const token = createSelector(state => state.sesionReducer, sesionReducer => sesionReducer.token);
export const logOut = createSelector(state => state.sesionReducer, sesionReducer => sesionReducer.token);
export const passwordReset = createSelector(state => state.sesionReducer, sesionReducer => sesionReducer.reset);

export const userData = createSelector(state => state.sesionReducer, sesionReducer => sesionReducer.user);
export const logOutUser = createSelector(state => state.sesionReducer, sesionReducer => sesionReducer.user);
export const signUpR = createSelector(state => state.sesionReducer, sesionReducer => sesionReducer.userCreated);
export const deleteSignUpR = createSelector(state => state.sesionReducer, sesionReducer => sesionReducer.userCreated);
export const signUpClientR = createSelector(state => state.sesionReducer, sesionReducer => sesionReducer.userClient);
export const deletesignUpClientR = createSelector(state => state.sesionReducer, sesionReducer => sesionReducer.userClient);