/*
DOCUMENTACIÓN IMPORTANTE
Los parámetros deben ser recibidos como un json {op1: 1, op2: [1,2,3], op3: "Hola"}
Si no hay parámetros se debe enviar un json vacío {}
Los datos deben ser recibidos en forma de json
El token de autenticación es opcional
*/

import { OK } from '../utilidades/Constantes';

// const path = "http://localhost:8002/api/1.0"
// const path = "http://3.19.161.168:8002/api/1.0" //Pruebas
const path = "https://api.cityscan.co/api/1.0" //Producción
const axios = require('axios');
const queryString = require('query-string');

export const getData = async (url, params, token, additionalHeaders = {}) => {
    const headers = { ...additionalHeaders }
    const queryParams = Object.keys(params).length ? `?${queryString.stringify(params)}` : ''

    if (token)
        headers.Authorization = setAuth(token)

    let config = {
        method: 'get',
        url: `${path}/${url}${queryParams}`,
        headers
    };
    try {
        let response = await axios(config);
        return response.data
    } catch (error) {
        return error
    }
}

export const postData = async (url, params, data, token) => {
    const headers = {
        'Content-Type': 'application/json',
    }

    if (token) {
        headers.Authorization = setAuth(token)
    }
    let config = {
        method: 'POST',
        url: `${path}/${url}${setParamsString(params)}`,
        headers,
        data
    };
    
    try {
        let response = await axios(config);
        return response.data
    } catch (error) {
        return error
    }
}

export const patchData = async (url, params, data, token) => {
    const headers = {
        'Content-Type': 'application/json',
    }

    if (token)
        headers.Authorization = setAuth(token)

    let config = {
        method: 'patch',
        url: `${path}/${url}${setParamsString(params)}`,
        headers,
        data
    };
    try {
        let response = await axios(config);
        return response.data
    } catch (error) {
        return error
    }
}

export const deleteData = async (url, params, token) => {
    const headers = {}

    if (token)
        headers.Authorization = setAuth(token)

    let config = {
        method: 'delete',
        url: `${path}/${url}${setParamsString(params)}`,
        headers
    };
    try {
        let response = await axios(config);
        return response.data ? response.data : OK
    } catch (error) {
        return error
    }
}

const setParamsString = (params) => {
    let strParams = ""
    Object.keys(params).forEach((key, i) => {
        let value = params[key]
        if (typeof value === 'object') {
            let options = ""
            value.forEach(opcion => options += opcion + ",")
            value = options
        }
        if (i === 0)
            strParams += key + "=" + value
        else
            strParams += "&" + key + "=" + value

    })
    return strParams ? `?${params}` : ""
}

const setAuth = token => token ? `token ${token}` : '';