import { getData } from '../proxy/BackendREST'
import { LAYER_CATEGORIES_LIST, LAYER_CLASSMODEL_LIST, LAYER_LAYERS_LIST, LAYER_POINTS_LIST, LAYER_POINT_DETAIL, TOURS_CATEGORIES_LIST } from '../utilidades/Endpoints'

export const TRAER_TOURS_CATEGORIES = 'TRAER_TOURS_CATEGORIES'
export const TRAER_LISTA_CLASSES = 'TRAER_LISTA_CLASSES'
export const TRAER_LISTA_CATEGORIAS_LAYERS = 'TRAER_LISTA_CATEGORIAS_LAYERS'
export const TREAR_LISTA_FILTRADA_LUGARES = 'TREAR_LISTA_FILTRADA_LUGARES'
export const TREAR_LUGAR_SELECCIONADO = 'TREAR_LUGAR_SELECCIONADO'
export const ERROR_CONSULTA_LUGAR = 'ERROR_CONSULTA_LUGAR'
export const CARGANDO_LUGAR = 'CARGANDO_LUGAR'

export const traerToursCategories = () => {
    return async dispatch => {

        const data = await getData(TOURS_CATEGORIES_LIST, {}, null)
        dispatch({
            payload: data,
            type: TRAER_TOURS_CATEGORIES
        })
    }
}

export const traerClasses = () => {
    return async dispatch => {

        let classes = {};
        const gettingClasses = async () => {
            const classesTemp = await getData(LAYER_CLASSMODEL_LIST, {}, null)
            classesTemp.forEach(aClass => {
                classes[aClass.id] = aClass
            })
        }

        let layers;
        const gettingLayers = async () => {
            layers = await getData(LAYER_LAYERS_LIST, {}, null)
        }

        await Promise.all([
            gettingClasses(),
            gettingLayers()
        ])

        layers.forEach(layer => {
            let currentClassLayers = classes[layer.class_model].layers || {}
            currentClassLayers = {...currentClassLayers, [layer.id]: layer}
            classes[layer.class_model] = {...classes[layer.class_model], layers: currentClassLayers}
        })
        
        dispatch({
            payload: classes,
            type: TRAER_LISTA_CLASSES
        })
    }
}

export const traerCategoriasDeLayer = (idClassModel, idLayer) => {
    return async dispatch => {
        const tempLayerCategories = await getData(LAYER_CATEGORIES_LIST, {layer: idLayer}, null)
        let layerCategories = {}
        
        tempLayerCategories.forEach(category => {
            layerCategories[category.id] = category
        })

        dispatch({
            payload: layerCategories,
            type: TRAER_LISTA_CATEGORIAS_LAYERS,
            idClassModel,
            idLayer
        })
    }
}

export const traerLugaresFiltrados = (categories, city) => {
    return async dispatch => {
        const tempPointsList = await getData(LAYER_POINTS_LIST, {
            lat: city?.position?.coordinates[1] || 4.7085729,
            lng: city?.position?.coordinates[0] || -74.0828704,
            max_distance: 50000,
            categories: categories.join(",")
        }, null, {
        })

        let points = {}

        tempPointsList.forEach(point => {
            points[point.id] = point
        })

        dispatch({
            payload: points,
            type: TREAR_LISTA_FILTRADA_LUGARES
        })
        
    }
}

export const traerLugar = (id) => {
    return async dispatch => {
        dispatch({
            payload: CARGANDO_LUGAR,
            type: TREAR_LUGAR_SELECCIONADO
        })
        try {
            const lugar = await getData(`${LAYER_POINT_DETAIL}${id}`, {}, null)
            dispatch({
                payload: lugar,
                type: TREAR_LUGAR_SELECCIONADO
            })
        } catch (error) {
            dispatch({
                payload: ERROR_CONSULTA_LUGAR,
                type: TREAR_LUGAR_SELECCIONADO
            })
        }

        
    }
}