import { getData, postData } from "../proxy/BackendREST"
import { traerToken } from "../proxy/LocalStorage"
import { CITIES_LIST, TOURS_LIST, TOUR, LIKE_DISLIKE_TOUR, TOUR_REVIEWS, CREATE_TOUR_REVIEW, MY_TOURS_LIST, COUNTRIES_LIST } from "../utilidades/Endpoints"

export const TRAER_TOURS = 'TRAER_TOURS'
export const TRAER_MIS_TOURS = 'TRAER_MIS_TOURS'
export const TRAER_TOP_TOURS = 'TRAER_TOP_TOURS'
export const TRAER_TOURS_FILTRADA = 'TRAER_TOURS_FILTRADA'
export const LOADING_TOURS = 'LOADING_TOURS'
export const LOADING_TOP_TOURS = 'LOADING_TOP_TOURS'
export const TRAER_COUNTRIES = 'TRAER_COUNTRIES'
export const TRAER_CITIES = 'TRAER_CITIES'
export const TRAER_TOURS_FAVORITOS = 'TRAER_TOURS_FAVORITOS'
export const TRAER_TOUR = 'TRAER_TOUR'
export const TRAER_REVIEWS = 'TRAER_REVIEWS'
export const CREAR_REVIEW = 'CREAR_REVIEW'
export const ELIMINAR_TOURS_USUARIO = 'ELIMINAR_TOURS_USUARIO'

export const traerTours = (limit, offset, criterioDeOrden) => {
    return async dispatch => {
        dispatch({
            payload: true,
            type: LOADING_TOURS
        })
        
        const data = await getData(TOURS_LIST, {
            limit,
            offset,
            order_by: criterioDeOrden
        }, traerToken())

        dispatch({
            payload: data,
            type: TRAER_TOURS
        })
    }
}

export const traerTopTours = () => {
    return async dispatch => {
        dispatch({
            payload: true,
            type: LOADING_TOP_TOURS
        })

        const data = await getData(TOURS_LIST, { limit: 10, offset: 0, order_by: '-transactions' }, null)
        dispatch({
            payload: data,
            type: TRAER_TOP_TOURS
        })
    }
}

export const traerToursFiltrados = (limit, offset, country = null, city = null, categories = null, duracion = null, criterioDeOrden) => {
    return async dispatch => {
        const params = {}
        if (country && country !== -1)
            params.country = country

        if (city && city !== -1)
            params.city = city

        if (categories)
            params.categories = categories

        if (duracion) {
            params.min_duration = duracion.min
            params.max_duration = duracion.max
        }
        params.limit = limit
        params.offset = offset
        params.order_by = criterioDeOrden

        const data = await getData(TOURS_LIST, params, null)
        dispatch({
            payload: data,
            type: TRAER_TOURS_FILTRADA
        })
    }
}

export const traerTour = id => {
    return async dispatch => {

        const data = await getData(`${TOUR}${id}/`, {}, traerToken())

        dispatch({
            payload: { data, id },
            type: TRAER_TOUR
        })
    }
}

export const traerCountries = () => {
    return async dispatch => {
        const data = await getData(COUNTRIES_LIST, {})

        dispatch({
            payload: data,
            type: TRAER_COUNTRIES
        })
    }
}

export const traerCities = (country) => {
    return async dispatch => {
        const params = {
            lat: 4.709298899999999,
            lng: -74.0596389,
            order_by: '-number_experiences',
            with_tours: "t"
        };

        if(country && country !== -1)
            params["country"] = country
        
        const data = await getData(CITIES_LIST, params, null)

        if(!data.isAxiosError) 
            dispatch({
                payload: data,
                type: TRAER_CITIES
            })
    }
}

export const cambiarLike = async (token, idExperience) => {
    await postData(LIKE_DISLIKE_TOUR, {}, { 'experience_id': idExperience }, token)
}

export const traerReviewsTour = (idExperience, offset, limit) => {
    return async dispatch => {

        const data = await getData(TOUR_REVIEWS,
            {
                "experience": idExperience,
                "offset": offset,
                "limit": limit
            }, null)

        dispatch({
            payload: data,
            type: TRAER_REVIEWS
        })
    }
}

export const crearReviewTour = (comentario, idExperience, token, rating) => {
    return async dispatch => {
        const data = await postData(CREATE_TOUR_REVIEW, {}, {
            "commentary": comentario,
            "experience": idExperience,
            "rating": rating
        }, token)

        dispatch({
            payload: data,
            type: CREAR_REVIEW
        })
    }
}

export const traerBookedTours = () => {
    return async dispatch => {
        const data = await getData(MY_TOURS_LIST, {}, traerToken())
        dispatch({
            payload: data,
            type: TRAER_MIS_TOURS
        })
    }
}

export const traerLikedTours = () => {
    return async dispatch => {
        const data = await getData(TOURS_LIST, { option: 'favorites' }, traerToken())
        dispatch({
            payload: data,
            type: TRAER_TOURS_FAVORITOS
        })
    }
}

export const eliminarToursUsuario = () => {
    return dispatch => {
        dispatch({
            type: ELIMINAR_TOURS_USUARIO
        })
    }
}