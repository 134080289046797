import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CargandoContext from '../../cargandoContext/CargandoContext';
import DialogoBotones from '../../../general/dialogoBotones/DialogoBotones';
import { authorizing } from '../../../../utilidades/TextosIdioma';
import { connect } from 'react-redux';
import { token, signUpR, signUpClientR } from '../../../../reducers/sesionReducer';
import { traerToken, signUp, signUpClient } from '../../../../actions/sesionActions'
import { bindActionCreators } from 'redux';

const SignUp = ({ setLogin, setSignUp, cerrar, token, signUpR, signUpClientR, signUp, signUpClient }) => {

    const { idioma, correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)
    const [usuario, setUsuario] = useState({
        username: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        //profile_picture: GloboPersonas
    })
    const [usuarioCliente,] = useState({
        id: '',
        phone: '',
        isClient: true,
        company: "",
        website: "",
        work_position: "",
        address: "",
        zip_code: "",
        paypal_info: "",
        customer_id: "",
        email: ""

    })
    const orderid = require('order-id')('mysecret');
    const [phoneNumber, setPhoneNumber] = useState('')
    const [password, setPassword] = useState('')
    const [registroExitoso, setRegistroExitoso] = useState(false)
    const [confirmPassword, setConfirmPassword] = useState('')
    const [igual, setIgual] = useState(false)
    const [error, setError] = useState({
        estado: false,
        mensaje: ""
    })
    const textos = authorizing.signup
    const textosConfirmar = authorizing.confirmedYourEmail

    const registrarCliente = async () => {
        if (signUpR) {
            if (!signUpR.response && !signUpClientR) {
                setError({estado: false})
                correrIndicadorCarga()
                const id = orderid.generate();
                usuarioCliente.customer_id = id
                usuarioCliente.phone = phoneNumber
                usuarioCliente.email = usuario.email
                let usuarioTemp = signUpR.user
                usuarioCliente.user = parseInt(usuarioTemp)
                await crearUsuarioCliente()
                setRegistroExitoso(true)
                quitarIndicadorCarga()
            } else if(signUpR.response) {
                const error = signUpR.response?.data
                if (error && error.password1) {
                    setError({
                        estado: true,
                        mensaje: error.password1
                    })
                } else if (error && error.email) {
                    setError({
                        estado: true,
                        mensaje: error.email
                    })
                } else {
                    setError({
                        estado: true,
                        mensaje: textos.error[idioma]
                    })
                }
            }
        }
    }

    useEffect(() => {
        registrarCliente()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, signUpR, signUpClientR])

    const cambiarALogin = () => {
        setLogin(true)
        setSignUp(false)
    }
    const crearUsuarioCliente = async () => {
        await signUpClient(usuarioCliente)
    }
    const handleToken = (e) => {
        setUsuario({ ...usuario, [e.target.name]: e.target.value })
    }
    const handlePassword = (e) => {
        setPassword(e.target.value)
    }
    const handlePasswordConfirmation = (e) => {
        setConfirmPassword(e.target.value)
    }
    const handleTelefono = (e) => {
        setPhoneNumber(e.target.value)
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        correrIndicadorCarga()
        usuario.password1 = password
        usuario.password2 = confirmPassword
        usuario.username = usuario.email.replace('@', '')
        if (password === confirmPassword) {
            await signUp(usuario)
        } else {
            setIgual(true)
        }
        quitarIndicadorCarga()
    }

    const cerrarConfirmar = () => {
        setRegistroExitoso(false)
        cerrar()
    }

    return (
        <form className='contenedor-dialogo'>
            {registroExitoso && <DialogoBotones titulo={textosConfirmar.title[idioma]}
                descripcion={textosConfirmar.description[idioma]}
                cerrar={cerrarConfirmar} click1={cerrarConfirmar}
                boton1={textosConfirmar.button[idioma]} />}
            <h1 className="titulo-dialogo-auth" >
                {textos.titulo[idioma]}
            </h1>
            <form className='sign-up' onSubmit={handleSubmit}>
                <div className='names'>
                    <div className='name-container'>
                        <label className='label-signup'>
                            {textos.nombre[idioma]}
                        </label>
                        <input required type='text' className='input-signup-names' onChange={handleToken} name='first_name' value={usuario.first_name} />
                    </div>
                    <div className='name-container'>
                        <label className='label-signup'>
                            {textos.apellido[idioma]}
                        </label>
                        <input required type='text' className='input-signup-names' onChange={handleToken} name='last_name' value={usuario.last_name} style={{ alignSelf: 'flex-end' }} />
                    </div>
                </div>
                <div className='email'>
                    <label className='label-email-signup'>
                        {textos.email[idioma]}
                    </label>
                    <input required type='email' className='input-email-signup' onChange={handleToken} name='email' value={usuario.email} />
                    <label className='send'>
                        {textos.confirmation[idioma]}
                    </label>
                </div>
                <div className='password'>
                    <div className='password-container'>
                        <label className='label-password-signup'>
                            {textos.password[idioma]}
                        </label>
                        <input required type='password' className='input-password-signup' onChange={handlePassword} name='password' value={password} />
                    </div>
                    <div className='password-container'>
                        <label className='label-password-signup'>
                            {textos.confirmPassword[idioma]}
                        </label>
                        <input required type='password' className='input-password-signup' onChange={handlePasswordConfirmation} name='confirmPassword' value={confirmPassword} style={{ alignSelf: 'flex-end' }} />


                    </div>
                </div>
                {igual &&
                    <label className='texto-rojo password-not'>
                        {textos.coinciden[idioma]}
                    </label>
                }
                <div className='phone-number'>
                    <label className='label-phonenumber-signup'>
                        {textos.telefono[idioma]}
                    </label>
                    <input type='number' className='input-phonenumber-signup' onChange={handleTelefono} name='phoneNumber' value={phoneNumber} />

                </div>
                {error.estado &&
                    <label className='texto-rojo' style={{ marginLeft: '2vw', fontSize: '1.2vw', fontWeight: '350' }}>
                        {error.mensaje}
                    </label>
                }
                <div className="contenedor-botones-dialogo">
                    <button id="btn-primario" className="boton-dialogo" type='submit'>
                        {textos.boton[idioma]}
                    </button>
                </div>

            </form>

            <label className='label-pointer' onClick={cambiarALogin}>
                {textos.cuenta[idioma]}
            </label>
        </form>
    )
};
SignUp.propTypes = {
    setLogin: PropTypes.func.isRequired,
    setSignUp: PropTypes.func.isRequired,
}
const mapStateToProps = (state) => ({
    token: token(state),
    signUpR: signUpR(state),
    signUpClientR: signUpClientR(state)

})

const mapDispatchToProps = dispatch => bindActionCreators({
    traerToken,
    signUp,
    signUpClient
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(SignUp)