import React, { useContext, useEffect, useState } from 'react'
import BtnMenu from '@material-ui/icons/Person'
import DialogoAuth from './DialogoAuth';
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom';
import CargandoContext from '../cargandoContext/CargandoContext';
import { authorizing } from '../../../utilidades/TextosIdioma';
import personaSel from "../../../assets/ic_persona_selected.svg";
import persona from "../../../assets/ic_persona.svg"
import { traerToken, borrarToken } from '../../../proxy/LocalStorage';
import { connect } from 'react-redux';
import { hacerLogOut, deleteSignUp, deleteSignUpClient, traerUsuario } from '../../../actions/sesionActions'
import { eliminarToursUsuario } from '../../../actions/toursActions'
import { logOut, logOutUser, deleteSignUpR, deletesignUpClientR, userData } from '../../../reducers/sesionReducer'
import { bindActionCreators } from 'redux';
import CloseIcon from '../../../assets/ic_cerrar.svg';

const UserHeader = ({ user, traerUsuario, hacerLogOut, changeZIndex, deleteSignUp, deleteSignUpClient, eliminarToursUsuario }) => {

    const { idioma, } = useContext(CargandoContext)
    let textos = authorizing.header
    const [visible, setVisible] = useState(false)
    const [visibleResponsive, setVisibleResponsive] = useState(false)

    const [dropDownMenu, setDropDownMenu] = useState(false)

    const [contexto, setContexto] = useState('')
    let ancho = window.innerWidth

    const [token, setToken] = useState('')
    let location = useLocation();
    let history = useHistory();
    const cerrar = () => {
        setVisible(!visible)

    }
    const cerrarResponsive = (contexto) => {
        cambiarContexto(contexto)
        cerrarDropDown()
    }
    const cambiarContexto = (context) => {
        setContexto(context)
        setVisible(!visible)
    }
    const checkDropDown = () => {
        setDropDownMenu(!dropDownMenu)
        setVisibleResponsive(true)
        if (ancho > 800) {
            if (!dropDownMenu) {
                document.addEventListener('click', cerrarDropDown)
            }
        } else {
            changeZIndex(true)
        }
    }
    const cerrarDropDown = () => {
        setDropDownMenu(false)
        setVisibleResponsive(false)
        if (ancho > 800) {
            document.removeEventListener('click', cerrarDropDown)
        } else {
            changeZIndex(false)
        }
    }
    const logOut = () => {
        borrarToken()
        hacerLogOut()
        eliminarToursUsuario()
        deleteSignUp()
        deleteSignUpClient()
        if (location.pathname === "/profile") {

            history.push('/')
        }
        cerrarDropDown()
    }
    useEffect(() => {
        setToken(traerToken())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem('key')])

    useEffect(() => {
        if (!user) {
            traerUsuario(traerToken())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div>
            {
                visible &&
                <DialogoAuth cerrar={cerrar} context={contexto} />
            }{
                user && user.user_client?.profile_picture ?
                    <img src={user.user_client.profile_picture} onClick={checkDropDown} alt="" className={"imagen-perfil-usuario"} /> :
                    <BtnMenu src={location.pathname === "/profile" ? personaSel : persona} onClick={checkDropDown} className={location.pathname === "/profile" ? "profile-selected" : "profile"} />
            }
            {
                (dropDownMenu || ancho <= 800) &&
                <div className='dropdown' style={{ right: visibleResponsive ? 0 : '-100vw' }}>
                    {ancho <= 800 &&
                        <img src={CloseIcon} alt="cerrar" className='cerrar' onClick={() => cerrarDropDown()} />
                    }
                    <div className='contenedor-dropdown'>
                        {!token &&
                            <label onClick={() => cerrarResponsive('signup')} className='boton-dropdown'>
                                {textos.signup[idioma]}
                            </label>
                        }
                        {!token &&
                            <label className='linea' />
                        }
                        {!token &&
                            <label onClick={() => { cerrarResponsive('login') }} className='boton-dropdown'>
                                {textos.login[idioma]}
                            </label>
                        }
                        {token &&
                            <label className='boton-dropdown' onClick={() => {
                                history.push("/profile")
                                cerrarDropDown()
                            }}>
                                {textos.perfil[idioma]}
                            </label>
                        }
                        {token &&
                            <label className='linea' />
                        }
                        {token &&
                            <label onClick={logOut} className='boton-dropdown'>
                                {textos.logout[idioma]}
                            </label>
                        }
                    </div>
                </div>
            }
        </div>
    )
};
UserHeader.propTypes = {
    hacerLogOut: PropTypes.func.isRequired,
    changeZIndex: PropTypes.func,
}
const mapStateToProps = (state) => ({
    user: userData(state),
    logOut: logOut(state),
    logOutUser: logOutUser(state),
    deleteSignUpR: deleteSignUpR(state),
    deleteSignUpClientR: deletesignUpClientR(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
    traerUsuario,
    hacerLogOut,
    deleteSignUp,
    deleteSignUpClient,
    eliminarToursUsuario
}, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(UserHeader)