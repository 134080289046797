import { createSelector } from "reselect"
import { TOUR_ACTUAL_COMPRADO } from "../actions/pasarelaActions"
import {
    LOADING_TOP_TOURS,
    LOADING_TOURS,
    TRAER_CITIES,
    TRAER_MIS_TOURS,
    TRAER_TOP_TOURS,
    TRAER_TOURS,
    TRAER_TOURS_FAVORITOS,
    TRAER_TOURS_FILTRADA,
    TRAER_TOUR,
    TRAER_REVIEWS,
    CREAR_REVIEW,
    ELIMINAR_TOURS_USUARIO,
    TRAER_COUNTRIES
} from "../actions/toursActions"

const INITIAL_STATE = {
    uidUser: null,
    user: null,
    tours: null,
    loadingTours: false,
    toursFiltrados: null,
    next: false,
    tourActual: null,
    idTourActual: null,
    reviewsTourActual: null,
    toursFavoritos: null,
    misTours: null,
    cities: null,
    countries: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRAER_TOURS:
            const tours = {}

            action.payload.results.forEach(tour => {
                tours[tour.id] = tour
            })

            return { ...state, tours, loadingTours: false, next: !!action.payload.next }

        case TRAER_MIS_TOURS:
            return { ...state, misTours: action.payload }

        case TRAER_TOURS_FAVORITOS:
            return { ...state, toursFavoritos: action.payload }

        case TRAER_TOP_TOURS:
            const topTours = {}

            action.payload.results.forEach(tour => {
                topTours[tour.id] = tour
            })

            return { ...state, topTours, loadingTopTours: false }

        case TRAER_TOURS_FILTRADA:
            const toursFiltrados = {}

            action.payload.results.forEach(tour => {
                toursFiltrados[tour.id] = tour
            })

            return { ...state, toursFiltrados, next: !!action.payload.next }
        case LOADING_TOURS:
            return { ...state, loadingTours: action.payload }
        case LOADING_TOP_TOURS:
            return { ...state, loadingTopTours: action.payload }
        case TRAER_COUNTRIES:
            return { ...state, countries: action.payload }
        case TRAER_CITIES:
            return { ...state, cities: action.payload }
        case TRAER_TOUR:
            return { ...state, tourActual: action.payload.data, idTourActual: action.payload.id }
        case TRAER_REVIEWS:
            return { ...state, reviewsTourActual: action.payload }
        case CREAR_REVIEW:
            return {
                ...state, reviewsTourActual: {
                    ...state.reviewsTourActual,
                    results: [
                        ...state.reviewsTourActual.results,
                        action.payload
                    ]
                }
            }
        case ELIMINAR_TOURS_USUARIO:
            return { ...state, toursFavoritos: null, misTours: null }
        case TOUR_ACTUAL_COMPRADO:
            return { ...state, tourActual: {...state.tourActual, is_mine: true}}
        default:
            return { ...state }
    }
}

export const getTours = createSelector(state => state.toursReducer, toursReducer => toursReducer.tours && Object.keys(toursReducer.tours).map(key => toursReducer.tours[key]))

export const getTopTours = createSelector(state => state.toursReducer, toursReducer => toursReducer.topTours && Object.keys(toursReducer.topTours).map(key => toursReducer.topTours[key]))

export const getToursFiltrados = createSelector(state => state.toursReducer, toursReducer => toursReducer.toursFiltrados && Object.keys(toursReducer.toursFiltrados).map(key => toursReducer.toursFiltrados[key]))

export const getLoadingTours = createSelector(state => state.toursReducer, toursReducer => toursReducer.loadingTours)

export const getLoadingTopTours = createSelector(state => state.toursReducer, toursReducer => toursReducer.loadingTopTours)

export const getCountries = createSelector(state => state.toursReducer, toursReducer => {
    toursReducer.countries && toursReducer.countries.forEach((country, i) => {
        if(country.code === "CO"){
          toursReducer.countries.splice(i, 1);
          toursReducer.countries.unshift(country);
        }
    });
    
    return toursReducer.countries
})

export const getCities = createSelector(state => state.toursReducer, toursReducer => {
    return toursReducer.cities?.sort((a, b) => {
        let aPriority = 0;
        let bPriority = 0;

        if(a.id === 43) {
            aPriority = 2;
        }

        if(a.id === 31 || a.id === 32 || a.id === 28 || a.id === 26 || a.id === 30 || a.id === 29) {
            aPriority = 1;
        }

        if(b.id === 31 || b.id === 32 || b.id === 28 || b.id === 26 || b.id === 30 || b.id === 43 || b.id === 29) {
            bPriority = 1;
        }

        if(b.id === 43) {
            bPriority = 2;
        }

        return bPriority - aPriority;
    })
})

export const getBookedTours = createSelector(state => state.toursReducer, toursReducer => toursReducer.misTours)

export const getLikedTours = createSelector(state => state.toursReducer, toursReducer => toursReducer.toursFavoritos)

export const getExistNextTours = createSelector(state => state.toursReducer, toursReducer => toursReducer.next)

export const getTourActual = createSelector(state => state.toursReducer, toursReducer => toursReducer.tourActual)

export const getIdTourActual = createSelector(state => state.toursReducer, toursReducer => toursReducer.idTourActual)

export const getReviewsTourActual = createSelector(state => state.toursReducer, toursReducer => toursReducer.reviewsTourActual)