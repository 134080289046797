import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Login from './components/Login';
import Password from './components/Password';
import SignUp from './components/SignUp';
import ChangePassword from './components/ChangePassword';

const DialogoAuth = ({ cerrar, context }) => {
    const [login, setLogin] = useState(false)
    const [signUp, setSignUp] = useState(false)
    const [password, setPassword] = useState(false)
    const [changePassword, setChangePassword] = useState(false)
    
    useEffect(() => {
        if(context==='password'){
            setPassword(true)
            setLogin(false)
            setSignUp(false)
            setChangePassword(false)
        }
        else if(context==='login'){
            setLogin(true)
            setPassword(false)
            setSignUp(false)
            setChangePassword(false)
        }
        else if(context==='signup'){
            setSignUp(true)
            setPassword(false)
            setLogin(false)
            setChangePassword(false)
        }
        else if(context==='changepassword'){
            setLogin(false)
            setSignUp(false)
            setPassword(false)
            setLogin(false)
            setChangePassword(true)
        }
    },[context])
    return (
        <div className='fondo-dialogo-auth'>
            <div className='fondo-dialogo-rc' onClick={cerrar}/>
                {login &&
                    <Login cerrar = {cerrar} setLogin={setLogin} setSignUp={setSignUp} setPassword = {setPassword}/>
                }
                {password &&
                    <Password cerrar ={cerrar} setPassword={setPassword} setSignUp={setSignUp}/>
                }
                {signUp &&
                    <SignUp cerrar ={cerrar} setLogin={setLogin} setSignUp={setSignUp}/>
                }
                {changePassword &&
                    <ChangePassword cerrar ={cerrar}/>
                }
        </div>
    )
};
DialogoAuth.propTypes = {
    cerrar: PropTypes.func.isRequired,
    context: PropTypes.string.isRequired,
}

export default DialogoAuth