import React, { useContext } from 'react'
import CargandoContext from '../cargandoContext/CargandoContext';
import facebook from "../../../assets/ic_facebook.svg"
import instagram from "../../../assets/ic_instagram.svg"
import twitter from "../../../assets/ic_twitter.svg"
import { footer } from '../../../utilidades/TextosIdioma'
import { ESPANOL } from '../../../utilidades/Constantes';
import TerminosES from  "./../../../assets/terminos/T&C CS-TM ES.pdf";
import TerminosEN from  "./../../../assets/terminos/T&C CS-TM EN.pdf";

const Footer = props => {
    let textos = footer
    const { idioma } = useContext(CargandoContext)

    return (
        <div className="footer">
            <div className="content">
                <div className="contact">
                    <h4 className="titulo">{textos.contacto[idioma][0]}</h4>
                    <div className="contact-grid">
                        <p className="bold">{textos.contacto[idioma][1]}</p>
                        <p>cityscan@info-projects.com</p>
                        <p className="bold">{textos.contacto[idioma][2]}</p>
                        <p>{textos.contacto[idioma][3]}</p>
                    </div>
                    <a className="terminos-condiciones" target="_blank" rel="noopener noreferrer" href={idioma === ESPANOL ? TerminosES : TerminosEN}>
                        {
                            textos.terminos[idioma]
                        }
                    </a>
                </div>
                <div className="social">
                    <h4 className="find">{textos.encuentranos[idioma]}</h4>
                    <div className="social-media">
                        <a href="https://www.instagram.com/cityscanco/" target="_blank" rel="noopener noreferrer">
                            <img className="red-social" src={instagram} alt="Instagram" />
                        </a>
                        <a href="https://twitter.com/CityScanCO" target="_blank" rel="noopener noreferrer">
                            <img className="red-social" src={twitter} alt="Twitter" />
                        </a>
                        <a href="https://www.facebook.com/CityScanCO/" target="_blank" rel="noopener noreferrer">
                            <img className="red-social" src={facebook} alt="Facebook" />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

Footer.propTypes = {

}

export default Footer
