import { createSelector } from "reselect"
import array from 'lodash/array';
import {
    TRAER_METODOS,
    ELIMINAR_TARJETA_ERROR,
    ELIMINAR_TARJETA,
    ACTUALIZAR_TARJETA
} from "../actions/pasarelaActions"

const INITIAL_STATE = {
    uidUser: null,
    user: null,
    metodos: null,
    errorTarjeta: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRAER_METODOS:
            return { ...state, metodos: action.payload }
        case ELIMINAR_TARJETA:
            array.remove(state.metodos, (metodo) => metodo.id === action.payload)
            return { ...state, metodos: state.metodos }
        case ELIMINAR_TARJETA_ERROR:
            return { ...state, errorTarjeta: action.payload }
        case ACTUALIZAR_TARJETA:
            let tarjetas = []
            tarjetas.push(action.payload)
            return { ...state, metodos: tarjetas }
        default:
            return { ...state }
    }
}

export const getMetodos = createSelector(state => state.pasarelaReducer, pasarelaReducer => pasarelaReducer.metodos)
export const getError = createSelector(state => state.pasarelaReducer, pasarelaReducer => pasarelaReducer.errorTarjeta)
