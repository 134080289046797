import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import CargandoContext from '../cargandoContext/CargandoContext';
import { ESPANOL, INGLES } from '../../../utilidades/Constantes'
import { header } from '../../../utilidades/TextosIdioma'
import flagEN from "../../../assets/flag_en.svg"
import flagES from "../../../assets/flag_es.svg"
import UserHeader from '../authorizing/UserHeader';
import logoCS from "../../../assets/logo_cs_header.svg"

const Header = () => {
    let textos = header
    let location = useLocation();
    let history = useHistory()
    const [visible, setVisible] = useState(false)

    const serTransparente = (location.pathname === "/home" || location.pathname === "/about")

    const { idioma, cambiarIdioma } = useContext(CargandoContext)

    const [transparente, setTransparente] = useState(true)

    const changeZIndex =(displayed) =>{
        if(displayed){
            document.getElementById("menu").style.zIndex = 10;
        }else{
            document.getElementById("menu").style.zIndex = 21;
        }
    }

    useEffect(() => {
        const scroll = (e) => {
            const top = window.scrollY
            if (top > 100 && transparente) setTransparente(false)
            if (top < 100 && !transparente) setTransparente(true)
        }
        window.addEventListener('scroll', scroll)
        return () => {
            window.removeEventListener('scroll', scroll)
        }
        // eslint-disable-next-line
    })

    const handleClick = (ruta) => {
        history.push(ruta)
        setVisible(false)
    }

    return (
        <div className={`header ${transparente && serTransparente ? 'header-transparente' : ''}`} id="header">
            <div className="contenido-header">
                <div className="logo">
                    <img className="imgLogo" onClick={() => handleClick("/")} src={logoCS} alt="Logo" />
                </div>
                <button className="btn-menu-responsive" onClick={() => setVisible(!visible)} id='menu'>
                    <svg width="29" height="23"  fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g id="ic-menu">
                            <path id="arriba" style={{ animation: `${visible ? "rotar-arriba" : "alinear-arriba"} 1s ease forwards` }} d="M1.95946 0H27.0405C27.2979 0 27.5527 0.0646644 27.7904 0.190301C28.0281 0.315938 28.2441 0.500087 28.4261 0.732233C28.608 0.96438 28.7524 1.23998 28.8508 1.54329C28.9493 1.84661 29 2.1717 29 2.5V2.5C29 3.16304 28.7936 3.79893 28.4261 4.26777C28.0586 4.73661 27.5602 5 27.0405 5H1.95946C1.70214 5 1.44734 4.93534 1.20961 4.8097C0.971874 4.68406 0.755865 4.49991 0.573912 4.26777C0.206442 3.79893 0 3.16304 0 2.5V2.5C0 1.83696 0.206442 1.20107 0.573912 0.732233C0.941382 0.263392 1.43978 0 1.95946 0V0Z" fill="white" />
                            <path id="medio" style={{ animation: `${!visible ? "aparecer" : "ocultar"} 1s ease forwards` }} d="M1.95946 9H27.0405C27.5602 9 28.0586 9.26339 28.4261 9.73223C28.7936 10.2011 29 10.837 29 11.5V11.5C29 12.163 28.7936 12.7989 28.4261 13.2678C28.0586 13.7366 27.5602 14 27.0405 14H1.95946C1.70214 14 1.44734 13.9353 1.20961 13.8097C0.971874 13.6841 0.755865 13.4999 0.573912 13.2678C0.206442 12.7989 0 12.163 0 11.5V11.5C0 10.837 0.206442 10.2011 0.573912 9.73223C0.941382 9.26339 1.43978 9 1.95946 9V9Z" fill="white" />
                            <path id="abajo" style={{ animation: `${visible ? "rotar-abajo" : "alinear-abajo"} 1s ease forwards` }} d="M1.95946 18H27.0405C27.5602 18 28.0586 18.2634 28.4261 18.7322C28.7936 19.2011 29 19.837 29 20.5C29 21.163 28.7936 21.7989 28.4261 22.2678C28.0586 22.7366 27.5602 23 27.0405 23H1.95946C1.70214 23 1.44734 22.9353 1.20961 22.8097C0.971874 22.6841 0.755865 22.4999 0.573912 22.2678C0.206442 21.7989 0 21.163 0 20.5C0 19.837 0.206442 19.2011 0.573912 18.7322C0.941382 18.2634 1.43978 18 1.95946 18Z" fill="white" />
                        </g>
                    </svg>
                </button>
                <div className="user-responsive">
                    <UserHeader changeZIndex ={changeZIndex}/>
                </div>
                <div className={"options"} style={{ left: visible ? 0 : '-100vw' }}>
                    <div className={location.pathname === "/home" ? "nav-option-selected" : "nav-option"} onClick={() => handleClick("/home")}>{textos[idioma][0]}</div>
                    <div className={location.pathname === "/tours" ? "nav-option-selected" : "nav-option"} onClick={() => handleClick("/tours")}>{textos[idioma][1]}</div>
                    <div className={location.pathname === "/about" ? "nav-option-selected" : "nav-option"} onClick={() => handleClick("/about")}>{textos[idioma][2]}</div>
                    <div className={location.pathname === "/help" ? "nav-option-selected" : "nav-option"} onClick={() => handleClick("/help")}>{textos[idioma][3]}</div>
                    <div className={location.pathname === "/tourmakers" ? "nav-option-selected" : "nav-option"} onClick={() => window.open('https://tourmakers-landing.cityscan.co/','_self')}>{textos[idioma][4]}</div>
                    <div className="user-pc">
                        <UserHeader />
                    </div>
                    <div className="language flex jc-space-between ai-center" onClick={() => cambiarIdioma(idioma === ESPANOL ? INGLES : ESPANOL)}>
                        {idioma === ESPANOL && <div className="flex-cont">
                            <img src={flagES} alt={"Spanish flag"} className="bandera" />
                            <p className={"language-es"}>{idioma.toUpperCase()}</p>
                        </div>
                        }
                        {idioma === INGLES && <div className="flex-cont">
                            <img src={flagEN} alt={"English flag"} className="bandera bandera-en" />
                            <p className={"language-en"}>{idioma.toUpperCase()}</p>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

Header.propTypes = {

}

export default Header
