import React from 'react'
import { PulseLoader } from 'react-spinners';

const LoadingIndicator = () => {
    return (
        <div className='indicador-carga'>
            <PulseLoader
                size={`1.5em`}
                color={'#887AB8'}/>
        </div>
    )
}

export default LoadingIndicator