// Tours

export const TOURS_LIST = 'tours/experiences'

export const MY_TOURS_LIST = 'tours/myexperiences'

export const TOUR = 'tours/experience/'

export const TOUR_REVIEWS = 'tours/experiencereviews'

export const CREATE_TOUR_REVIEW = 'tours/createexperiencereview/'

export const COUNTRIES_LIST = "tours/countries"

export const CITIES_LIST = 'tours/citiesCountries'

export const LIKE_DISLIKE_TOUR = 'tours/likeExperience/'

// Layer

export const TOURS_CATEGORIES_LIST = 'layer/tourCategory/'

export const LAYER_CLASSMODEL_LIST = 'layer/classmodel/'

export const LAYER_CATEGORIES_LIST = 'layer/category'

export const LAYER_LAYERS_LIST = 'layer/layer/'

export const LAYER_POINTS_LIST = 'layer/point'

export const LAYER_POINT_DETAIL = 'layer/point/'

// Transactions

export const PAYMENT_METHODS = 'transactions/payment-method/'

export const CREATE_PAYMENT = 'transactions/payment-method/'

export const PAY_EXPERIENCE = 'transactions/buy-experience/'

export const BUY_EXPERIENCE = 'tours/buyExperience/'

// Auth

export const PASSWORD_RESET = 'rest-auth/password/reset/'

export const PASSWORD_CHANGE = 'rest-auth/password/change/'

export const LOGIN = 'rest-auth/login/'

export const LOGOUT = 'rest-auth/logout/'

export const AUTH_USER = 'rest-auth/user/';

export const USER = 'crm/userdata/'

export const USER_CLIENT = 'crm/userclient/'

export const SIGNUP = 'crm/user-registration/'

export const SIGNUPCLIENT = 'crm/userclient/'
