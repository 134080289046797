import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import CargandoContext from '../../cargandoContext/CargandoContext';
import { authorizing } from '../../../../utilidades/TextosIdioma';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { traerToken, traerUsuario, deleteSignUpClient } from '../../../../actions/sesionActions'
import { SIN_VERIFICAR, token, userData } from '../../../../reducers/sesionReducer'
import { guardarToken } from '../../../../proxy/LocalStorage';
const Login = ({ cerrar, setLogin, setSignUp, setPassword, token, usuarioBD, traerToken, traerUsuario, deleteSignUpClient }) => {

    const { idioma, correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)
    const [usuario, setUsuario] = useState({
        email: '',
        password: ''
    })
    const [error, setError] = useState({
        estado: false,
        mensaje: ""
    })
    const textos = authorizing.login
    useEffect(() => {
        if (token) {
            if (!token.response && (!usuarioBD || usuarioBD.response)) {
                traerUsuario(token.key)
            } else if (!token.response && usuarioBD) {
                if (usuarioBD === SIN_VERIFICAR) {
                    setError({
                        estado: true,
                        mensaje: textos.verify[idioma]
                    })
                } else {
                    guardarToken(token.key)
                    cerrar()
                }
            } else {
                const error = token.response?.data
                if (error && error.non_field_errors && error.non_field_errors[0]) {
                    setError({
                        estado: true,
                        mensaje: error.non_field_errors[0]
                    })
                } else {
                    setError({
                        estado: true,
                        mensaje: textos.error[idioma]
                    })
                }
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token, usuarioBD])

    useEffect(() => {
        setError({
            estado: false
        })
    }, [])

    const cambiarASignUp = () => {
        setLogin(false)
        setSignUp(true)
    }
    const cambiarAPassword = () => {
        setLogin(false)
        setPassword(true)

    }
    const handleToken = (e) => {
        setUsuario({ ...usuario, [e.target.name]: e.target.value })

    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        setError({
            estado: false
        })
        correrIndicadorCarga()
        await traerToken(usuario)
        quitarIndicadorCarga()
    }

    return (
        <div className='contenedor-dialogo'>
            <h1 className="titulo-dialogo-auth" > {textos.titulo[idioma]}</h1>
            <form className='login' onSubmit={handleSubmit}>
                <div className='email'>
                    <label className='login-label'>
                        {textos.credencial[idioma]}
                    </label>
                    <input required type="email" className='input-email-login' onChange={handleToken} name='email' value={usuario.email} />
                </div>
                <div className='password'>
                    <label className='password-label'>
                        {textos.password[idioma]}
                    </label>
                    <input required type="password" className='input-login-password' onChange={handleToken} name='password' value={usuario.password} />
                </div>
                {
                    error.estado &&
                    <label className='texto-rojo' style={{ marginLeft: '2vw', marginTop: '-1vw' }}>
                        {error.mensaje}
                    </label>
                }
                <div className="contenedor-botones-dialogo">
                    <button id="btn-primario" className="boton-dialogo" type='submit' >
                        {textos.boton[idioma]}
                    </button>
                </div>
            </form>

            <label className='label-pointer' onClick={cambiarAPassword}>
                {textos.olvidaste[idioma]}
            </label>
            <label className='label-pointer' onClick={cambiarASignUp}>
                {textos.cuentaNueva[idioma]}
            </label>
        </div>
    )
};
Login.propTypes = {
    setLogin: PropTypes.func.isRequired,
    setPassword: PropTypes.func.isRequired,
    setSignUp: PropTypes.func.isRequired
}
const mapStateToProps = (state) => ({
    token: token(state),
    usuarioBD: userData(state)
})

const mapDispatchToProps = dispatch => bindActionCreators({
    traerToken,
    traerUsuario,
    deleteSignUpClient
}, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(Login)