

import { patchData, postData } from "../proxy/BackendREST"
import { LOGIN, SIGNUP, SIGNUPCLIENT, USER, USER_CLIENT, PASSWORD_CHANGE, AUTH_USER, PASSWORD_RESET } from "../utilidades/Endpoints"

export const LOG_IN = 'LOG_IN'

export const LOG_OUT = 'LOG_OUT'

export const SIGN_UP = 'SIGN_UP'

export const SIGN_UP_CLIENT = 'SIGN_UP_CLIENT'

export const USER_CONSTANT = 'USER'

export const USER_INFO_CHANGE = 'USER_INFO_CHANGE'

export const LOGOUT_USER = 'LOGOUT_USER'

export const DELETE_SIGN_UP = 'DELETE_SIGN_UP'

export const DELETE_SIGN_UP_CLIENT = 'DELETE_SIGN_UP_CLIENT'

export const traerToken = user => {

    return async dispatch => {
        const data = await postData(LOGIN, {}, user, null)
        dispatch({
            payload: data,
            type: LOG_IN
        })
    }
}

export const hacerLogOut = () => {
    return async dispatch => {
        dispatch({
            payload: null,
            type: LOG_OUT
        })
    }
}

export const signUp = (user) => {
    return async dispatch => {
        const data = await postData(SIGNUP, {}, user, null)
        dispatch({
            payload: data,
            type: SIGN_UP
        })
    }
}
export const deleteSignUp = () => {
    return async dispatch => {
        dispatch({
            payload: null,
            type: DELETE_SIGN_UP
        })
    }
}
export const signUpClient = (user) => {
    return async dispatch => {
        const data = await postData(SIGNUPCLIENT, {}, user, null)
        dispatch({
            payload: data,
            type: SIGN_UP_CLIENT
        })
    }
}
export const deleteSignUpClient = () => {
    return async dispatch => {
        dispatch({
            payload: null,
            type: DELETE_SIGN_UP_CLIENT
        })
    }
}

export const passwordReset = async (email) => {
    return await postData(PASSWORD_RESET, {}, {email})
}


export const changePasswordUser = async (token, data) => {
    let changeData = {
        ...data,
        OLD_PASSWORD_FIELD_ENABLED: true,
        LOGOUT_ON_PASSWORD_CHANGE: false
    }
    const response = await postData(PASSWORD_CHANGE, {}, changeData, token)
    return response
}

export const traerUsuario = (token) => {
    return async dispatch => {
        const data = await postData(USER, {}, null, token)
        dispatch({
            payload: data,
            type: USER_CONSTANT
        })
    }
}

export const editarUsuario = (idUser, userData, token) => {
    return async dispatch => {

        const { first_name, last_name, phone, profile_picture } = userData;

        const formData = new FormData()

        Object.keys(userData).forEach(key => {
            if (userData[key])
                formData.append(key, userData[key])
        })

        const res = await patchData(`${USER_CLIENT}${idUser}/`, {}, formData, token)
        await patchData(AUTH_USER, {}, { first_name, last_name }, token);
        const user_client = { phone }

        if (res["profile_picture"]) user_client["profile_picture"] = res["profile_picture"];
        else user_client["profile_picture"] = profile_picture

        dispatch({
            payload: {
                user: {
                    first_name,
                    last_name,
                    user_client
                },
            },
            type: USER_INFO_CHANGE
        })
    }
}