// Posibles estados de respuesta

export const OK = 'OK'
export const ERROR = 'ERROR'
export const DOCUMENTO_NO_ENCONTRADO = 'DOCUMENTO_NO_ENCONTRADO'
export const NO_HAY_RESULTADOS = 'NO_HAY_RESULTADOS'

// Posibles codigos para errores en el registro

export const ERRORES_REGISTRO = {
    'auth/weak-password': 'La contraseña debe contener al menos 6 caracteres',
    'auth/email-already-in-use': 'El correo ya esta siendo usado por otro usuario'
}

// Posibles codigos para errores en el cambio de contraseña

export const ERRORES_CAMBIO_CONTRASEÑA = {
    'auth/user-not-found': 'No existe un usuario con este correo',
}

// Constantes para tipos en la escritura por lotes

export const UPDATE = 'UPDATE'
export const DELETE = 'DELETE'
export const SET = 'SET'

//Idiomas

export const ESPANOL = 'es'
export const INGLES = 'en'

//Constantes que indican la rutas de las tiendas donde está la aplicación
export const APP_STORE = 'https://itunes.apple.com/us/app/cityscan/id1313840851?mt=8&at=10l6Xd&ct=jc4fde8xzz00xkod01g9a'
export const PLAY_STORE = 'https://play.google.com/store/apps/details?id=co.infoprojects.cityscan&pcampaignid=MKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'

// Criterios para ordenar los tours
export const CRITERIOS_DE_ORDEN = {
    MENOR_A_MAYOR_PRECIO: "price",
    MAYOR_A_MENOR_PRECIO: "-price",
    ULTIMOS_TOURS: "-updated" 
}

export const ESTADOS_ERROR = {
    ESTADO_PAGO_REQUERIDO: 402,
    ESTADO_BAD_REQUEST: 400
}