export const cambiarIdioma = (idioma) => {
    localStorage.setItem('idioma', idioma)
}

export const traerIdioma = () => {
    let idioma = localStorage.getItem('idioma') ?? "en"
    return idioma
}

export const guardarToken = (token) => {
    localStorage.setItem('key', token)
}

export const traerToken = () => {
    let token = localStorage.getItem('key')
    if (token)
        return token
    else
        return null
}

export const borrarToken = () => {
    localStorage.removeItem('key')
}