import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import CargandoContext from '../../cargandoContext/CargandoContext';
import { authorizing } from '../../../../utilidades/TextosIdioma';
import { passwordReset } from '../../../../actions/sesionActions'
import DialogoBotones from '../../dialogoBotones/DialogoBotones';

const Password = ({ setPassword, setSignUp, cerrar }) => {

    const { idioma, correrIndicadorCarga, quitarIndicadorCarga } = useContext(CargandoContext)
    const textos = authorizing.password
    const textosReset = authorizing.passwordReset
    const [usuario, setUsuario] = useState({
        email: '',
    })
    const [exito, setExito] = useState(false)
    const [error, setError] = useState({
        estado: false,
        mensaje: ""
    })
    const cambiarASignUp = () => {
        setPassword(false)
        setSignUp(true)
    }
    const handleTelefono = (e) => {
        setUsuario({ ...usuario, [e.target.name]: e.target.value })
    }
    const handleSubmit = async (e) => {
        e.preventDefault()
        correrIndicadorCarga()
        const data = await passwordReset(usuario.email)
        if (data && data.success) {
            setExito(true)
        } else {
            setError({
                estado: true,
                mensaje: textos.error[idioma]
            })
        }
        quitarIndicadorCarga()
    }

    const cerrarConfirmar = () => {
        setExito(false)
        cerrar()
    }

    return (
        <div className='contenedor-dialogo'>
            {exito && <DialogoBotones titulo={textosReset.title[idioma]}
                descripcion={textosReset.description[idioma]}
                cerrar={cerrarConfirmar} click1={cerrarConfirmar}
                boton1={textosReset.button[idioma]} />}
            <h1 className="titulo-dialogo-auth" >
                {textos.titulo[idioma]}
            </h1>
            <form className='password-sc' onSubmit={handleSubmit}>
                <div className='email'>
                    <label className='label-email-password'>
                        {textos.email[idioma]}
                    </label>
                    <input name="email" type="email" className='input-email-password' onChange={handleTelefono} />
                </div>
                <label className='signal'>
                    {textos.signal[idioma]}
                </label>
                {error.estado && <label id="red" className='signal'>
                    {error.mensaje}
                </label>}
                <div className="contenedor-botones-dialogo">
                    <button id="btn-primario" className="boton-dialogo" type='submit'>
                        {textos.enviar[idioma]}
                    </button>
                </div>
            </form>


            <label className='label-pointer' onClick={cambiarASignUp}>
                {textos.cuenta[idioma]}
            </label>
        </div>
    )
};
Password.propTypes = {
    setPassword: PropTypes.func.isRequired,
    setSignUp: PropTypes.func.isRequired,
    cerrar: PropTypes.func.isRequired,
}
export default Password