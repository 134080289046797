import { deleteData, getData, postData } from "../proxy/BackendREST"
import { ERROR, ESTADOS_ERROR, OK } from "../utilidades/Constantes"
import { PAYMENT_METHODS, CREATE_PAYMENT, BUY_EXPERIENCE, PAY_EXPERIENCE } from "../utilidades/Endpoints"

export const TRAER_METODOS = 'TRAER_METODOS'
export const ELIMINAR_TARJETA = 'ELIMINAR_TARJETA'
export const ELIMINAR_TARJETA_ERROR = 'ELIMINAR_TARJETA_ERROR'
export const ACTUALIZAR_TARJETA = 'ACTUALIZAR_TARJETA'
export const TOUR_ACTUAL_COMPRADO = 'TOUR_ACTUAL_COMPRADO'

export const traerMedios = (token) => {
    return async dispatch => {

        const data = await getData(PAYMENT_METHODS, {}, token)
        dispatch({
            payload: data,
            type: TRAER_METODOS
        })
    }
}

export const eliminarTarjeta = (idTarjeta, token) => {
    return async dispatch => {
        let data = await deleteData(`${PAYMENT_METHODS}${idTarjeta}`, {}, token)
        if (data === OK) {
            dispatch({
                payload: idTarjeta,
                type: ELIMINAR_TARJETA
            })
        } else {
            dispatch({
                payload: true,
                type: ELIMINAR_TARJETA_ERROR
            })
        }
    }
}

export const cerrarErrorTarjeta = () => {
    return dispatch => {
        dispatch({
            payload: false,
            type: ELIMINAR_TARJETA_ERROR
        })
    }
}

export const crearMetodoPago = async (metodo, token) => {
    const data = await postData(CREATE_PAYMENT, {}, metodo, token)

    if(data?.response?.status === ESTADOS_ERROR.ESTADO_BAD_REQUEST) {
        return ERROR
    } else {
        return data
    }
}

export const actualizarTarjeta = (tarjeta) => {
    return dispatch => {
        dispatch({
            payload: tarjeta,
            type: ACTUALIZAR_TARJETA
        })
    }
}

export const pagarTour = async (token, precio) => {
    const data = await postData(PAY_EXPERIENCE, {}, {
        'cost': precio
    }, token)

    if(data?.response?.status === ESTADOS_ERROR.ESTADO_PAGO_REQUERIDO) {
        return ERROR
    } else {
        return data
    }
}

export const comprarTour = (token, idExperience, refEpayco) => {
    return async dispatch => {
        await postData(BUY_EXPERIENCE, {}, {
            'experience_id': idExperience,
            'ref_epayco': refEpayco
        }, token)

        dispatch({
            type: TOUR_ACTUAL_COMPRADO
        })
    }
}