import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { authorizing } from '../../../../utilidades/TextosIdioma'
import CargandoContext from '../../../general/cargandoContext/CargandoContext'

export const CuentaConfirmada = () => {

    const txtConfirmed = authorizing.accountConfirmed
    const history = useHistory()
    const {idioma} = useContext(CargandoContext)

    return (
        <div className="cuenta-confirmada">
            <p className="texto">{txtConfirmed[idioma]}</p>
            <svg onClick={()=>history.push("/home")} className="cerrar" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M7.40085 6.50002L12.0584 1.84252C12.163 1.7203 12.2177 1.56309 12.2115 1.4023C12.2053 1.2415 12.1386 1.08898 12.0248 0.975193C11.9111 0.86141 11.7585 0.794753 11.5977 0.788542C11.437 0.782331 11.2797 0.837024 11.1575 0.941691L6.50002 5.59919L1.84252 0.935302C1.7203 0.830635 1.56308 0.775943 1.40229 0.782154C1.2415 0.788364 1.08897 0.855021 0.975185 0.968804C0.861403 1.08259 0.794745 1.23511 0.788535 1.39591C0.782324 1.5567 0.837017 1.71392 0.941684 1.83614L5.59918 6.50002L0.935295 11.1575C0.868415 11.2148 0.814096 11.2853 0.775748 11.3645C0.7374 11.4438 0.71585 11.5301 0.712451 11.6181C0.709053 11.7061 0.723879 11.7939 0.755998 11.8758C0.788118 11.9578 0.836839 12.0323 0.899102 12.0946C0.961364 12.1568 1.03583 12.2055 1.11781 12.2377C1.1998 12.2698 1.28754 12.2846 1.37552 12.2812C1.46351 12.2778 1.54985 12.2563 1.62911 12.2179C1.70837 12.1796 1.77885 12.1252 1.83613 12.0584L6.50002 7.40086L11.1575 12.0584C11.2797 12.163 11.437 12.2177 11.5977 12.2115C11.7585 12.2053 11.9111 12.1386 12.0248 12.0249C12.1386 11.9111 12.2053 11.7585 12.2115 11.5978C12.2177 11.437 12.163 11.2797 12.0584 11.1575L7.40085 6.50002Z" fill="white"/>
            </svg>
        </div>
    )
}
