import React from 'react'
import PropTypes from 'prop-types'

const DialogoBotones = ({ titulo, descripcion, boton1, click1, boton2, click2, cerrar }) => {

    return (
        <div className="fondo-dialogo-generico">
            <div className="fondo-dialogo-rc" onClick={cerrar} />
            <div className="contenedor-dialogo">
                <h1 className="titulo-dialogo">{titulo}</h1>
                <p className="descripcion-dialogo">
                    {descripcion}
                </p>
                <div className="contenedor-botones-dialogo">
                    <button id="btn-primario" className="boton-dialogo" onClick={click1}>
                        {boton1}
                    </button>
                    {boton2 &&
                        <button id="btn-secundario" className="boton-dialogo" onClick={click2}>
                            {boton2}
                        </button>
                    }
                </div>
            </div>
        </div>
    )
};

DialogoBotones.propTypes = {
    titulo: PropTypes.string.isRequired,
    descripcion: PropTypes.string.isRequired,
    boton1: PropTypes.string.isRequired,
    click1: PropTypes.func.isRequired,
    boton2: PropTypes.string,
    click2: PropTypes.func,
    cerrar: PropTypes.func.isRequired,
    styles: PropTypes.object
};

export default DialogoBotones