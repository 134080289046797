export const header = {
    en: [
        "Home",
        "Discover",
        "About",
        "Help",
        "Tourmakers",
    ],
    es: [
        "Inicio",
        "Descubre",
        "Nosotros",
        "Ayuda",
        "Tourmakers",
    ]
}

export const home = {
    video: {
        titulo: {
            en: 'DISCOVER PLACES AND ENJOY TOURS',
            es: 'DESCUBRE LUGARES Y DISFRUTA DE TOURS'
        },
        texto: {
            en: `WITH AUGMENTED REALITY AND
            AUTHENTIC LOCAL GUIDES`,
            es: `CON REALIDAD AUMENTADA Y
            AUTÉNTICOS GUÍAS LOCALES`
        }
    },
    opcionesAplicacion: [
        {
            titulo: {
                en: 'Enjoy Our Tours',
                es: 'Disfruta Nuestros tours'
            },
            texto: {
                en: 'Live the experience of having access to authentic virtual tours made by locals',
                es: 'Vive la experiencia de tener acceso a tours auténticos virtuales hechos por locales'
            },
            boton: {
                es: 'SABER MÁS',
                en: 'FIND OUT MORE'
            }
        },
        {
            titulo: {
                en: 'Discover the city',
                es: 'Descubrir la ciudad'
            },
            texto: {
                en: 'Find places with audio guides in a destination according to your preferences',
                es: 'Encuentra lugares con audio guías en un destino de acuerdo con tus preferencias'
            },
            boton: {
                es: 'SABER MÁS',
                en: 'FIND OUT MORE'
            }
        }
    ],
    seccionTourMaker: {
        titulo: {
            en: `become a
            TourMaker`,
            es: `conviértete en
            un TourMaker`
        },
        texto: {
            en: `Create tourist content, share with the world and earn by doing what you like the most!`,
            es: `¡Crea contenido turístico, compártelo con el mundo y gana haciendo lo que más te gusta!`
        },
        boton: {
            en: 'Join us now',
            es: 'Únete ahora'
        }
    },
    carruselVideos: {
        en: 'GET TO KNOW US BETTER',
        es: 'CONÓCENOS MEJOR',
    },
    tours: {
        titulo: {
            en: 'OUR AMAZING TOURS',
            es: 'NUESTROS INCREÍBLES TOURS'
        }
    },
    places: {
        titulo: {
            en: 'PICK THE CATEGORY THAT YOU LIKE THE MOST',
            es: 'ELIGE LA CATEGORÍA QUE PREFIERAS'
        }
    },
    carruselOpiniones: {
        titulo: {
            en: 'SHARED OPINIONS',
            es: 'OPINIONES COMPARTIDAS'
        },
        opiniones: [
            {
                nombre: {
                    en: 'Santiago Cuartas',
                    es: 'Santiago Cuartas'
                },
                comentario: {
                    en: '“Since I got to know CityScan, traveling is much easier and more fun”',
                    es: '“Desde que conocí CityScan, viajar es mucho más sencillo y divertido” '
                }
            },
            {
                nombre: {
                    en: 'Paola Vargas',
                    es: 'Paola Vargas'
                },
                comentario: {
                    en: '“Traveling with Ct Scan allows me to get to know more traditional places and support the local economy”',
                    es: '“Viajar acompañada de CityScan me permite conocer lugares más tradicionales y apoyar a la economía local”'
                }
            },
            {
                nombre: {
                    en: 'Natalia Torres',
                    es: 'Natalia Torres'
                },
                comentario: {
                    en: '“Being able to have access to fourth generation technologies applied to tourism has allowed me to explore the destinations visited in a completely different way and learn more about them. Super recommended CityScan!”',
                    es: '“Poder tener acceso a tecnologías de la cuarta generación aplicadas al turismo me ha permitido explorar los destinos visitados de una manera completamente diferente y aprender más de ellos. ¡Súper recomendado CityScan!”'
                }
            },
            {
                nombre: {
                    en: 'Jeisson Rodriguez',
                    es: 'Jeisson Rodriguez'
                },
                comentario: {
                    en: '“Now I see my own city with different eyes, I have known places that I did not even know existed, it is incredible”',
                    es: '“Ahora veo mi propia ciudad con otros ojos, he conocido lugares que ni sabía que existían, es increíble”'
                }
            },
            {
                nombre: {
                    en: 'Cole Carter',
                    es: 'Cole Carter'
                },
                comentario: {
                    en: '“I can enjoy virtual tours made by a local, see the places and listen to the guide as if he were next to me with the application and the best thing, I manage my time and I can listen to him as many times as I want”',
                    es: '“Puedo disfrutar de tours virtuales hecho por un local, ver los lugares y escuchar al guía como si estuviera al lado mío con la aplicación y lo mejor, yo manejo mi tiempo y puedo escucharlo cuantas veces quiera”'
                }
            },
            {
                nombre: {
                    en: 'Paula Osorio',
                    es: 'Paula Osorio'
                },
                comentario: {
                    en: '“It is a whole new tourism experience, a new bet on the future of the sector”',
                    es: '“Es toda una nueva experiencia de turismo, una nueva apuesta al futuro del sector”'
                }
            }
        ]
    }
}

export const tours = {
    botones: {
        tours: {
            en: 'SEE TOURS',
            es: 'VER TOURS'
        },
        lugares: {
            en: 'SEE PLACES',
            es: 'VER LUGARES'
        }
    },
    ordenar: {
        ordenar: {
            en: 'SORT',
            es: 'ORDENAR'
        },
        menosAMasPrecio: {
            en: 'Price low to high',
            es: 'Menos a más precio'
        },
        masAMenosPrecio: {
            en: 'Price high to low',
            es: 'Más a menos precio'
        },
        actualizadosRecientemente: {
            en: 'Recently updated',
            es: 'Actualizados recientemente'
        },
        cercanos: {
            en: 'Close to me',
            es: 'Cerca a mi'
        }
    },
    filtros: {
        seleccionar: {
            en: 'Select',
            es: 'Seleccionar'
        },
        pais: {
            en: 'COUNTRY',
            es: 'PAÍS'
        },
        destino: {
            en: 'DESTINATION',
            es: 'DESTINO'
        },
        ciudad: {
            en: 'CITY',
            es: 'CIUDAD'
        },
        duracion: {
            en: 'DURATION',
            es: 'DURACIÓN'
        },
        categoria: {
            en: 'CATEGORY',
            es: 'CATEGORÍA'
        },
        todas: {
            en: 'Any',
            es: 'Todas'
        }
    },
    tours: {
        titulo: {
            en: 'OUR TOURS',
            es: 'NUESTROS TOURS'
        },
        gratis: {
            en: 'FREE',
            es: 'GRATIS'
        },
        descubreMas: {
            en: 'FIND OUT MORE',
            es: 'DESCUBRE MÁS'
        }
    },
    places: {
        titulo: {
            en: 'What are you looking for?',
            es: '¿Qué estás buscando?'
        },
        mensajeFiltros: {
            en: '*Filter your search with the following categories',
            es: '*Filtra tu búsqueda con las siguientes categorías:'
        }
    }
}

export const about = {
    encabezado: {
        texto: {
            en: 'Discover, travel, and learn\n with cityscan',
            es: 'Descubre, viaja y aprende\n con cityscan'
        }
    },
    descripcion: {
        titulo: {
            en: 'WHAT IS CITYSCAN?',
            es: '¿QUÉ ES CITYSCAN?'
        },
        subtitulo: {
            en: 'TAKE A LOOK',
            es: 'ECHA UN VISTAZO'
        },
        texto: {
            en: 'CityScan is the new sustainable and innovative tourism community, based on online collaboration, which allows:',
            es: 'CityScan es la nueva comunidad turística sostenible e innovadora, basada en la colaboración en linea, que permite:'
        },
        items: [
            {
                titulo: {
                    en: "Explore",
                    es: "Explorar"
                },
                texto: {
                    en: "Non-traditional places and tourist attractions with audio guides and Augmented Reality (AR).",
                    es: "Lugares no tradicionales y atractivos turísticos con audio guías y Realidad Aumentada (RA)."
                }
            },
            {
                titulo: {
                    en: "Enjoy",
                    es: "Disfrutar"
                },
                texto: {
                    en: "Self-guided virtual tours prerecorded by the destination guides who will provide you with a lot of knowledge on your visit (virtual or face-to-face).",
                    es: "De tours virtuales autoguiados pregrabados por los guías del destino quienes te aportarán mucho conocimiento en tu visita (virtual o presencial)."
                }
            },
            {
                titulo: {
                    en: "Connect",
                    es: "Conectar"
                },
                texto: {
                    en: "Visitors and tourists with the talent and knowledge of the local guides of a destination.",
                    es: " Visitantes y turistas con el talento y conocimiento de los guías locales de un destino."
                }
            }
        ]
    },
    features: {
        titulo: {
            en: 'FIND ABOUT OUR FEATURES',
            es: 'CONOCE NUESTRAS CARACTERISTICAS'
        },
        features: [
            {
                titulo: {
                    en: 'FLEXIBLE',
                    es: 'FLEXIBLE'
                },
                texto: {
                    en: 'Use it where you want, when you want and with unlimited categories',
                    es: 'Usalo en donde quieras, cuando quieras y con categorías ilimitadas'
                }
            },
            {
                titulo: {
                    en: 'POWERFUL',
                    es: 'PODEROSA'
                },
                texto: {
                    en: 'You have at your disposal the latest technology on the market',
                    es: 'Tienes a tu disposición la última tecnología en el mercado'
                }
            },
            {
                titulo: {
                    en: 'USEFUL',
                    es: 'ÚTIL'
                },
                texto: {
                    en: 'Learn from the locals, explore destinations, don\'t get lost, and stay up-to-date',
                    es: 'Aprende de los locales, explora destinos, no te pierdas y mantente actualizado'
                }
            },
            {
                titulo: {
                    en: 'SIMPLE',
                    es: 'SIMPLE'
                },
                texto: {
                    en: 'It is very easy to use, learn and locate',
                    es: 'Es muy fácil de usar, aprender y ubicarse '
                }
            }
        ]
    },
    logros: {
        titulo: {
            en: 'OUR ACCOMPLISHMENtS',
            es: 'NuESTROS LOGROS'
        },
        logros: [
            {
                titulo: {
                    en: 'SOUTHSUMMIT',
                    es: 'SOUTHSUMMIT',
                },
                texto: {
                    en: 'Among the 10 finalists of 3,800 startups as the best tourism application worldwide',
                    es: 'Dentro de los 10 finalistas de 3.800 emprendimientos como mejor aplicación de turismo a nivel mundial'
                }
            },
            {
                titulo: {
                    en: 'UNWTO',
                    es: 'UNWTO',
                },
                texto: {
                    en: 'In the Tourism tech ventures event selected among the 10 best solutions in Colombia',
                    es: 'En el evento Tourism tech ventures seleccionados entre las 10 mejores soluciones de Colombia'
                }
            },
            // {
            //     titulo: {
            //         en: 'GLOBAL STARTUP WORKSHOP',
            //         es: 'GLOBAL STARTUP WORKSHOP',
            //     },
            //     texto: {
            //         en: 'Semi-finalists as best tourism application',
            //         es: 'Semi-finalistas como mejor aplicación de turismo'
            //     }
            // },
            {
                titulo: {
                    en: 'MINCOMERCIO',
                    es: 'MINCOMERCIO',
                },
                texto: {
                    en: 'Finalists in the Tourism innovation channel event',
                    es: 'Finalistas en el evento Tourism innovation channel'
                }
            }
        ]
    }
}

export const detalle = {
    pais: {
        en: "Country",
        es: "País"
    },
    destino: {
        en: "Destination",
        es: "Destino"
    },
    categoria: {
        en: "Category",
        es: "Categoria"
    },
    lugares: {
        en: "Places",
        es: "Lugares"
    },
    califica: {
        en: "Rate this tour",
        es: "Califica este tour"
    },
    comprar: {
        en: "I want this tour!",
        es: "¡Quiero este tour!"
    },
    comentarios: {
        en: "Comments",
        es: "Comentarios"
    },
    sitios: {
        en: "Places to visit",
        es: "Lugares por visitar"
    }
}

export const calificar = {
    titulo: {
        en: "Did you enjoy this tour?",
        es: "¿Disfrutaste el tour?"
    },
    texto: {
        en: "Give us feed back so we can make your experience better next time!",
        es: "¡Danos tu opinión para que podamos mejorar tu experiencia la próxima vez!"
    },
    comentario: {
        en: "Leave a comment here...",
        es: "Deja un comentario aquí..."
    },
    agradecimiento: {
        en: "THANK YOU!",
        es: "¡GRACIAS!"
    },
    opinion: {
        en: "Your opinion is very important to us.",
        es: "Tu opinión es muy importante para nosotros."
    },
    error: {
        en: "Please complete all the fields.",
        es: "Por favor, completa todos los campos."
    }
}

export const places = {
    titulo: {
        en: "Our places",
        es: "Nuestros lugares"
    },
    textoSinPlaces: {
        en: "Click on a category to find free places to visit, see pictures and listen to audio guides. CityScan offers you great plans to do in your spare time. Take advantage of these places to visit.",
        es: "Haga clic en una categoría para encontrar lugares gratuitos que pueda visitar, ver imágenes y escuchar audioguías. CityScan le ofrece excelentes planes para hacer en su tiempo libre. Aprovecha estos lugares para visitar."
    },
    textoSinResultados: {
        en: "At the moment we have no places to show you, try another category.",
        es: "En estos momentos no tenemos lugares que mostrarte, intentalo con otra categoría."
    },
}

export const download = {
    seccionDescargar: {
        nombre: {
            en: 'DOWNLOAD THE APP',
            es: 'DESCARGA LA APLICACIÓN',
        },
        titulo: {
            en: 'JOIN US NOW',
            es: 'UNETE AHORA',
        },
        texto: {
            en: 'CityScan is a complete platform for tourist and locals to discover places and tours using a mobile app with augmented reality and audio guides.',
            es: 'CityScan es una plataforma completa para que turistas y lugareños descubran lugares y recorridos utilizando una aplicación móvil con realidad aumentada y audioguías.',
        },
        boton: {
            en: 'GET THE APP',
            es: 'OBTEN LA APLICACIÓN',
        }
    },
    seccionPasos: {
        nombre: {
            en: 'WHAT YOU CAN DO IN CITYSCAN',
            es: 'LO QUE PUEDES HACER EN CITYSCAN',
        },
        primero: {
            titulo: {
                en: "Select",
                es: "Selecciona"
            },
            texto: {
                en: "The place you want to visit and the content categories you would like to see, according to your tastes and preferences.",
                es: "El lugar que quieres visitar y las categorías de contenido que te gustaría ver, de acuerdo a tus gustos y preferencias."
            }
        },
        segundo: {
            titulo: {
                en: "Explore and learn",
                es: "Explora y aprende"
            },
            texto: {
                en: "About the destinations and live the experience of doing a virtual or self-guided tour. *If you have your phone at the destination, you will be able to see the location of the places with Augmented Reality just by lifting your cell phone.",
                es: "Sobre los destinos y vive la experiencia de hacer un tour virtual o autoguiado. *Si estás con tu móvil en el destino, puedes ver la ubicación de los lugares con Realidad aumentada con tan solo levantar tu celular."
            }
        },
        tercero: {
            titulo: {
                en: "Rate and comment",
                es: "Califica y comenta"
            },
            texto: {
                en: "Your experience in the places and tours.",
                es: "Tu experiencia en los lugares y tours."
            }
        },
    }
}

export const help = {
    preguntas: {
        titulo: {
            en: 'Frequently asked',
            es: 'Preguntas frecuentes'
        },
        preguntas: [
            {
                pregunta: {
                    es: '¿Qué es CityScan?',
                    en: 'What is CityScan?'
                },
                respuesta: {
                    es: 'Cityscan es una solución turística, que permite descubrir destinos, crear y compartir  contenido turístico y hacer visible los negocios locales que quieran ser descubiertos. generando experiencias turísticas inmersivas, personalizadas e interactivas, no solo con la aplicación misma, si no con su entorno.',
                    en: 'Cityscan is a tourism solution that allows you to discover destinations, create and share tourism content, and make visible local businesses that want to be discovered. generating immersive, personalized and interactive tourist experiences, not only with the application itself, but with its environment.'
                }
            },
            {
                pregunta: {
                    es: '¿Para quién es CityScan?',
                    en: 'Who is CityScan for?'
                },
                respuesta: {
                    es: `1. Amantes de los viajes y las nuevas experiencias
                        2.  Quienes quieran experiencias virtuales autoguiadas
                        3. Turistas de la nueva era que quieran vivir el turismo usando tecnología de última generación como la Realidad aumentada para descubrir destinos y tours.
                        4. Quienes quieran tener contenido multimedia como audioguías, videos y mapas que te dicen como llegar a los sitios
                        5. Quienes quieran disfrutar recorridos y experiencias virtuales sin moverte
                        6. Turistas responsables con el medio ambiente. No más mapas en papel que se mojan, se pierden, no entendemos y la información se vuelve obsoleta
                        7. Digital nomads
                        8. Quienes tengan poco tiempo para viajar y no quieren perderlo en un destino
                        8. Personas que no les gusta tomarse mucho tiempo leyendo diferentes blogs e información en Internet.
                        9. Para turistas que les gusta aprender y visitar lugares alineados a sus gustos y preferencias.
                        10. Turistas cansado de pagar tours costosos y con afanes
                        11.  Personas que quieran manejar su tiempo a su antojo 
                        11. Personas que les gustaría contar con un guia local virtual, apoyar la economía de los creadores locales y viajar por su cuenta.
                        `,
                    en: `1. Lovers of travel and new experiences
                        2. Those who want self-guided virtual experiences
                        3. Tourists of the new era who want to experience tourism using the latest technology such as Augmented Reality to discover destinations and tours.
                        4. Those who want to have multimedia content such as audio guides, videos and maps that tell you how to get to the sites
                        5. Those who want to enjoy virtual tours and experiences without moving
                        6. Tourists responsible with the environment. No more paper maps that get wet, are lost, we do not understand and information becomes obsolete
                        7. Digital nomads
                        8. Those who have little time to travel and do not want to lose it in a destination
                        8. People who do not like to spend a lot of time reading different blogs and information on the Internet.
                        9. For tourists who like to learn and visit places aligned to their tastes and preferences.
                        10. Tourists tired of paying expensive tours and anxious
                        11. People who want to manage their time as they please
                        11. People who would like to have a virtual local guide, support the economy of local creators and travel on their own.
                        `
                }
            },

            {
                pregunta: {
                    es: '¿Cómo funciona?',
                    en: 'How does it work?'
                },
                respuesta: {
                    es: `1. Descarga la aplicación en tu dispositivo
                        2. Ingresa con tu usuario para conocerte mejor 
                        3. Escoge lo que quieres hacer
                            -Discover: En esta sección podrás explorar lo que tienes a tu alrededor: Sitios turísticos, sitios locales, tours, eventos y servicios de ciudad turísticos.
                            -Enjoy Tours: Es un espacio que te permitirá disfrutar de tours virtuales y autoguiados auténticos realizados por la comunidad, turistas, aficionados y guías profesionales a precios increíbles.
                            -Create tours (CityMakers): CityMakers es un canal de creación de contenidos especializados,  el cual por medio de un editor puedes agregar información relacionada a sitios turísticos, tours, eventos y publicidad. Esta información que creas será publicada en CityScan, plataforma descargada por turistas y locales curiosos por explorar y descubrir información  de interés turística.
                        4. Vive la experiencia de explorar destinos y tours por medio de realidad aumentada o desde casa!

                        `,
                    en: `1. Download the application on your device
                        2. Login with your username to get to know you better
                        3. Choose what you want to do
                            -Discover: In this section you can explore what you have around you: Tourist sites, local sites, tours, events and tourist city services.
                            -Enjoy Tours: It is a space that will allow you to enjoy authentic virtual and self-guided tours carried out by the community, tourists, amateurs and professional guides at incredible prices.
                            -Create tours (CityMakers): CityMakers is a channel for the creation of specialized content, which through an editor you can add information related to tourist sites, tours, events and advertising. This information that you create will be published on CityScan, a platform downloaded by tourists and locals curious to explore and discover information of tourist interest.
                        4. Live the experience of exploring destinations and tours through augmented reality or from home!
                        `
                }
            },

            {
                pregunta: {
                    es: '¿Que es Realidad aumentada?',
                    en: 'What is Augmented Reality?'
                },
                respuesta: {
                    es: 'La realidad aumentada consiste en combinar el mundo real con el virtual mediante un proceso informático, enriqueciendo la experiencia visual de los atractivos turísticos, facilitando la ubicación espacial y la exploración de sitios cerca de tí de manera visual y auditiva. Inscribete y vive la experiencia.',
                    en: 'Augmented reality consists of combining the real world with the virtual one through a computerized process, enriching the visual experience of tourist attractions, facilitating the spatial location and the exploration of sites near you visually and audibly. Sign up and live the experience.'
                }
            },

            {
                pregunta: {
                    es: '¿Cómo utilizo la realidad aumentada para ver los lugares a mi alrededor?',
                    en: 'How do I use augmented reality to see the places around me?'
                },
                respuesta: {
                    es: `Es muy fácil,  ingresa en nuestra aplicación, explora lo que tienes alrededor sin filtro, o aplica alguno de los filtros expuestos en nuestro menú , luego solo levanta tu celular o presiona  las gafitas y entrarás en realidad aumentada, así podrás descubrir lo que tienes alrededor tuyo.
                        - Si presionas los cuadrospodrás obtener información de los puntos a tu alrededor.
                        `,
                    en: `It is very easy, enter our application, explore what you have around you without a filter, or apply one of the filters displayed in our menu, then just pick up your cell phone or press the glasses and you will enter augmented reality, so you can discover what you have around of yours.
                        - If you press the squares you will be able to obtain information about the points around you.`
                }
            },

            {
                pregunta: {
                    es: '¿Que puedo ver en descubre el destino?',
                    en: 'What can I see in discover the destination?'
                },
                respuesta: {
                    es: `En esta sección podrás explorar lo que tienes a tu alrededor:
                        -Sitios turísticos
                        -Sitios locales
                        -Tours
                        -Eventos
                        -Servicios de ciudad y turísticos.. 
                        `,
                    en: `In this section you can explore what you have around you:
                        -Tourist sites
                        -Local sites
                        -Tours
                        -Events
                        -City and tourist services.`
                }
            },

            {
                pregunta: {
                    es: '¿Los tours funcionan sin internet?',
                    en: 'Do the tours work without internet?'
                },
                respuesta: {
                    es: 'Si,  podrás disfrutar de toda la información turística que tenemos para tí. Solo asegurate de hacer una pre descarga de la zona o destino que vas a explorar para garantizar el funcionamiento correcto sin la necesidad de tener internet.',
                    en: 'Yes, you can enjoy all the tourist information that we have for you. Just make sure to pre-download the area or destination that you are going to explore to guarantee correct operation without the need for internet.'
                }
            },

            {
                pregunta: {
                    es: '¿Usar la aplicación es gratis?',
                    en: 'Is using the application free?'
                },
                respuesta: {
                    es: 'Si! es completamente gratis, navega por un destino, obtén la información que requieras y disfruta de algunos tours completamente gratis.',
                    en: "Yes! It's completely free, browse a destination, get the information you require and enjoy some tours completely free."
                }
            },
            {
                pregunta: {
                    es: '¿Qué información podré ver por punto turístico?',
                    en: 'What information will I be able to see by tourist point?'
                },
                respuesta: {
                    es: `Podrás encontrar:
                        Fotos
                        Videos
                        Audios
                        Descripciones 
                        Horarios 
                        Ubicación geográfica 
                        Tener la experiencia de ver los puntos con realidad aumentada. 
                        
                        Cada información variará de acuerdo a la categoría seleccionada y los guías que ingresan el contenido.
                        `,
                    en: `You can find:
                        Photos
                        Videos
                        Audios
                        Descriptions
                        Schedule
                        Geographic location
                        Have the experience of seeing the points with augmented reality.
                        
                        Each information will vary according to the selected category and the guides that enter the content.
                        `
                }
            },
            {
                pregunta: {
                    es: '¿Puedo cambiar el rango de distancia?',
                    en: 'Can I change the distance range?'
                },
                respuesta: {
                    es: 'Si puedes, cuando ingreses el filtro de la búsqueda de interés, te aparecerá en la parte inferior la barra progresiva de distancia máxima (10 Km) y Mínima de (0 mts).',
                    en: 'Yes, when you enter the filter of the search of interest, the progressive bar of maximum distance (10 km) and Minimum of (0 meters) will appear at the bottom.'
                }
            },
            {
                pregunta: {
                    es: '¿Puedo explorar otros destinos?',
                    en: 'Can I explore other destinations?'
                },
                respuesta: {
                    es: 'Claro que si! podrás navegar a cualquier destino. Solo ingresa el destino que quieres explorar; la aplicación te ubicará automáticamente en el centro del destino de donde podrás navegar y obtener información turística.',
                    en: 'Of course! you can navigate to any destination. Just enter the destination you want to explore; The application will automatically locate you in the center of the destination from where you can navigate and obtain tourist information.'
                }
            },
            {
                pregunta: {
                    es: '¿Qué es es Enjoy tours?',
                    en: 'What is Enjoy tours?'
                },
                respuesta: {
                    es: 'Enjoy Tours es un espacio que te permitirá disfrutar de tours virtuales y autoguiados auténticos realizados por la comunidad, turistas, aficionados y guías profesionales a precios increíbles.',
                    en: 'Enjoy Tours is a space that will allow you to enjoy authentic virtual and self-guided tours carried out by the community, tourists, amateurs and professional guides at incredible prices.'
                }
            },
            {
                pregunta: {
                    es: '¿Quien hace los tours?',
                    en: 'Who does the tours?'
                },
                respuesta: {
                    es: 'Los tours son hechos por “TourMakers”, compuestos por nuestra comunidad, turistas, aficionados y guías profesionales, personas apasionadas por el turismo y por compartirle al mundo los sitios y contenido que agreguen valor a los destinos. ¿Te gustaría ser un TourMaker? inscribete ya en el siguiente link->',
                    en: 'The tours are made by "TourMakers", made up of our community, tourists, amateurs and professional guides, people who are passionate about tourism and sharing with the world the sites and content that add value to destinations. Would you like to be a TourMaker? Register now at the following link->'
                },
                url: 'https://tourmakers.cityscan.co/#/register-user'
            },
            {
                pregunta: {
                    es: '¿Que es un tour autoguiado?',
                    en: 'What is a self-guided tour?'
                },
                respuesta: {
                    es: 'Un tour autoguiado es aquel que fue hecho por un TourMaker previamente, grabando toda la información por sitio a visitar, puedes escoger ael que ms te guste, ir a los lugares con el orden que tu escojas y con los tiempos que tú quieres !.Los TourMakers te brindarán toda la información que necesitas para hacerlo tu solo o con tus amigos como:  los puntos georeferenciados y  la información en contenido multimedia a precios justos y económicos para que no te pierdas de nada! Escoge el tuyo ahora!',
                    en: "A self-guided tour is one that was done by a TourMaker previously, recording all the information per site to visit, you can choose the one you like best, go to the places with the order you choose and with the times you want! TourMakers will provide you with all the information you need to do it alone or with your friends, such as: georeferenced points and information in multimedia content at fair and affordable prices so that you don't miss out on anything! Choose yours now!"
                }
            },
            {
                pregunta: {
                    es: '¿Que es es un tour virtual?',
                    en: 'What is a virtual tour?'
                },
                respuesta: {
                    es: `El tour virtual es aquel que no no necesitas moverte de casa para poder explorar destinos y lugares distintos, deja que nuestros TourMakers te sorprendan y te hagan viajar estando en casa. Te mostrará videos, audios, descripciones, fotos y más para que vivas la experiencia. Pruébalo! 
                        ¿Que incluyen los tours?
                        
                        En los tours podrás encontrar:
                        Fotos
                        Videos, 
                        Audios
                        Descripciones 
                        Horarios 
                        Ubicación geográfica 
                        Tener la experiencia de ver los puntos con realidad aumentada. (Tours Autoguiados)
                    
                        Cada información variará de acuerdo a la categoría seleccionada y los guías que ingresan el contenido.`,
                    en: `The virtual tour is one that you do not need to move from home to explore different destinations and places, let our TourMakers surprise you and make you travel while at home. It will show you videos, audios, descriptions, photos and more so that you live the experience. Test it!
                        What do the tours include?
                    
                        In the tours you will find:
                        Photos
                        Videos,
                        Audios
                        Descriptions
                        Schedule
                        Geographic location
                        Have the experience of seeing the points with augmented reality. (Self-Guided Tours)
                    
                        Each information will vary according to the selected category and the guides that enter the content.`
                }
            },
            {
                pregunta: {
                    es: '¿Puedo ver el tour con realidad aumentada?',
                    en: 'Can I see the tour with augmented reality?'
                },
                respuesta: {
                    es: 'Si ! no te parece genial! podrá disfrutar de la realidad aumentada en los tours, el cual de ayudará ubicar los sitios del tour más fácilmente.',
                    en: "Yes ! you don't think it's cool! You can enjoy augmented reality in the tours, which will help to locate the tour sites more easily."
                }
            },
            {
                pregunta: {
                    es: '¿Cómo hago para comprar un tour?',
                    en: 'How do I buy a tour?'
                },
                respuesta: {
                    es: 'Cuando ya tengas seleccionado el tour que deseas obtener, dale comprar, agrega el método de pago y Voilá! ya podrás disfrutar de tu tour, el cual encontrarás en la ventana de “Mis experiencias”.',
                    en: 'When you have already selected the tour you want to obtain, press buy, add the payment method and Voilá! You can now enjoy your tour, which you will find in the "My experiences" window.'
                }
            },
            {
                pregunta: {
                    es: '¿Puedo compartir con otro usuario los tours comprados?',
                    en: 'Can I share the purchased tours with another user?'
                },
                respuesta: {
                    es: 'Puedes compartirlo con 3 personas más, compártelo con el usuario de CityScan de cada uno de los integrantes el cual lo podrán ver reflejados en “Mis experiencias”.',
                    en: 'You can share it with 3 more people, share it with the CityScan user of each of the members who will be able to see it reflected in "My experiences".'
                }
            },
            {
                pregunta: {
                    es: '¿Los tours funcionan sin internet?',
                    en: 'Do the tours work without internet?'
                },
                respuesta: {
                    es: 'Si,  podrás disfrutar de los tours y de toda la información turística que tenemos para tí. Solo asegurate de hacer una pre descarga de la zona o destino que vas a explorar para garantizar el funcionamiento correcto sin la necesidad de tener internet.',
                    en: 'Yes, you can enjoy the tours and all the tourist information that we have for you. Just make sure to pre-download the area or destination that you are going to explore to guarantee correct operation without the need for internet.'
                }
            },
            {
                pregunta: {
                    es: '¿Los tours son con un guía presencial o son autoguiados?',
                    en: 'Are the tours with a face-to-face guide or are they self-guided?'
                },
                respuesta: {
                    es: 'Los tours son 100 % autoguiados, te mostraremos la ubicación geográfica y podrás navegar en la información presentada, no más afanes, hazlo a tu gusto y tu tiempo explorar el destino.',
                    en: 'The tours are 100% self-guided, we will show you the geographical location and you can navigate in the information presented, no more worries, do it to your liking and your time explore the destination.'
                }
            },
            {
                pregunta: {
                    es: '¿Que pasa si compro un tour que era gratis y luego tiene precio?',
                    en: 'What happens if I buy a tour that was free and later has a price?'
                },
                respuesta: {
                    es: 'No pasa nada, quedarás con el tour guardado en tus experiencias. ',
                    en: 'Nothing happens, you will be left with the tour saved in your experiences.'
                }
            },
            {
                pregunta: {
                    es: '¿Cómo cambio mi contraseña?',
                    en: 'How do I change my password?'
                },
                respuesta: {
                    es: 'Cambia tu contraseña en el siguiente link:',
                    en: 'Change your password at the following link:'
                },
                url: 'https://tourmakers.cityscan.co/#/restore-password'
            },
            {
                pregunta: {
                    es: '¿Porque se rechazó mi pago?',
                    en: 'Why was my payment declined?'
                },
                respuesta: {
                    es: ` Es posible que se rechace tu tarjeta por algún motivo de los cuales te enlistamos a continuación:
                        *Error tipográfico en el número de la tarjeta. Prueba a introducir de nuevo el número de la tarjeta.
                        *Tarjeta de crédito caducada. Si la tarjeta no ha caducado, comprueba que la fecha se haya introducido correctamente.
                        *Error tipográfico en el código de verificación de la tarjeta (CVC). Comprueba el númer y vuelve a introducirlo.
                        *Se ha alcanzado el límite de crédito. Ponte en contacto con el proveedor de la tarjeta para obtener más información.
                    
                        Si has realizado todas estas comprobaciones y el problema persiste, ponte en contacto con el proveedor de la tarjeta.
                        `,
                    en: `It is possible that your card will be rejected for any reason of which we list below:
                        * Typographical error in the card number. Try entering the card number again.
                        * Expired credit card. If the card has not expired, check that the date has been entered correctly.
                        * Typographical error in the card verification code (CVC). Check the number and re-enter it.
                        * The credit limit has been reached. Contact the card provider for more information.
                        
                        If you've made all these checks and the problem persists, contact the card provider.`
                }
            }
        ]
    }
}

export const discover = {
    opiniones: [
        {
            en: "SHARE YOUR OPINION",
            es: "COMPARTE TU OPINIÓN"
        },
        {
            en: "SUBMIT YOUR OPINION",
            es: "INGRESA TU OPINIÓN"
        }
    ],
}

export const flotante = {
    en: 'NEED SOME HELP?',
    es: '¿NECESITAS AYUDA?',
}

export const footer = {
    contacto: {
        en: ['CONTACT US', 'E-mail', 'Phone', '+1 (954) 736-7990'],
        es: ['CONTÁCTANOS', 'Correo', 'Teléfono', '+57 (320) 345-0110']
    },
    encuentranos: {
        en: 'FIND US HERE',
        es: 'BÚSCANOS EN',
    },
    terminos: {
        en: "Terms",
        es: "Términos y condiciones"
    }
}

export const pasarela = {
    tarjeta: {
        titulo: {
            en: 'CHECKOUT',
            es: 'PAGO'
        },
        conMedio: {
            en: 'We remember your previous payment method, if you want to use another one feel free to change it down below',
            es: 'Recordamos tu anterior método de pago, si quieres usar otro puedes cambiarlo con el botón de abajo'
        },
        paymentMethod: {
            en: "Currently, you don't have any payment method registered yet! Click below to add payment details",
            es: 'Actualmente no tienes ningún método de pago. Haz click abajo para agregar uno'
        },
        tarjetCredito: {
            en: 'Credit card',
            es: 'Tarjeta de crdito'
        },
        pagarOnline: {
            en: 'Pay online',
            es: 'Pagar online'
        },
        misMetodos: {
            en: 'CURRENT PAYMENT METHOD',
            es: 'MÉTODO DE PAGO ACTUAL'
        },
        agregarMetodo: {
            en: 'ADD PAYMENT METHOD',
            es: 'AGREGAR MÉTODO DE PAGO'
        },
        editarMetodo: {
            en: 'EDIT PAYMENT METHOD',
            es: 'EDITAR MÉTODO DE PAGO'
        },
        pagar: {
            en: 'PAY NOW',
            es: 'PAGAR'
        },
        cambiar: {
            en: 'CHANGE PAYMENT METHOD',
            es: 'CAMBIAR MÉTODO DE PAGO'
        },
        error: {
            en: 'There was an error with the payment method. Please verify it is valid.',
            es: 'Hubo un error con el método de pago, verifica que tu tarjeta sea valida.'
        }
    },
    dialogoBorrarTarjeta: {
        titulo: {
            en: 'ERROR DELETING YOUR PAYMENT METHOD',
            es: 'ERROR ELIMINANDO TU MEDIO DE PAGO'
        },
        descripcion: {
            en: 'There was an error deleting your payment method, please try again later',
            es: 'Hubo un problema al eliminar tu método de pago, inténtalo de nuevo más tarde'
        },
    },
    titular: {
        tituloE: {
            en: 'EDIT PAYMENT METHOD',
            es: 'EDITAR METODO DE PAGO'
        },
        tituloA: {
            en: 'ADD PAYMENT METHOD',
            es: 'AGREGAR METODO DE PAGO'
        },
        tipoDoc: {
            en: 'Document type',
            es: 'Tipo de documento'

        },
        titularDoc: {
            en: 'Cardholder document',
            es: 'Documento del titular'
        },
        email: {
            en: 'Email',
            es: 'Correo'
        },
        phoneNumber: {
            en: 'Phone number',
            es: 'Número de telefono'
        },
        titularTarjeta: {
            en: 'Name of the cardholder',
            es: 'Nombre del titular'
        },
        select: {
            en: 'Select a document type',
            es: 'Seleccione un tipo de documento'
        },
        errorMetodo: {
            en: 'Please choose a payment method',
            es: 'Por favor escoge un método de pago'
        },
        errorDoc: {
            en: 'Please choose a document type',
            es: 'Por favor escoge un tipo de documento'
        },
        numeroTarjeta: {
            en: 'Card number',
            es: 'Número de tarjeta'
        },
        fecha: {
            en: 'Expiration date',
            es: 'Fecha de expiración'
        },
        pagar: {
            en: 'PAY NOW',
            es: 'PAGAR'
        },
        confirmar: {
            en: 'CONFIRM',
            es: 'CONFIRMAR'
        },
        cancel: {
            en: 'CANCEL',
            es: 'CANCELAR'
        },
        error: {
            en: 'There was an error with the payment method. Please verify it is valid.',
            es: 'Hubo un error con el método de pago, verifica que tu tarjeta sea valida.'
        },
        card: {
            en: 'CARD DATA',
            es: 'DATOS DE LA TARJETA'
        },
        contact: {
            en: 'CONTACT INFO',
            es: 'INFORMACIÓN DE CONTACTO'
        }

    },
    aprobado: {
        titulo: {
            en: "THANK YOU!",
            es: "¡GRACIAS!"
        },
        texto1: {
            en: "Thank you for joining the CityScan community and contributing to local tourism.",
            es: "Gracias por unirse a la comunidad CityScan y contribuir al turismo local."
        },
        texto2: {
            en: "For a better experience and virtual reality guides use the CityScan app.",
            es: "Para una mejor experiencia y guías de realidad virtual, use la aplicación CityScan."
        },
        app: {
            en: "CITYSCAN APP",
            es: "APP CITYSCAN"
        },
        web: {
            en: "CONTINUE ON WEB",
            es: "CONTINUAR EN LINEA"
        }
    }
}

export const authorizing = {
    header: {
        login: {
            en: 'Log in',
            es: 'Acceder'
        },
        signup: {
            en: 'Sign Up',
            es: 'Registrarse'
        },
        perfil: {
            en: 'My Profile',
            es: 'Mi perfil'
        },
        logout: {
            en: 'Log out',
            es: 'Cerrar sesión'
        }
    },
    login: {
        titulo: {
            en: 'LOG IN TO CITYSCAN',
            es: 'ACCEDER A CITYSCAN'
        },
        credencial: {
            en: 'E-mail',
            es: 'Correo'
        },
        password: {
            en: 'Password',
            es: 'Contraseña'
        },
        error: {
            en: 'There was an error. Please try again later',
            es: 'Hubo un error. Por favor intentalo más tarde'
        },
        verify: {
            en: 'You must verify your account to enter the platform, check your inbox',
            es: 'Debes verificar tu cuenta para poder ingresar a la plataforma, revisa tu bandeja de entrada'
        },
        boton: {
            en: 'LOG IN',
            es: 'Acceder'
        },
        olvidaste: {
            en: 'Forgot password?',
            es: '¿Olvidaste tu contraseña?'
        },
        cuentaNueva: {
            en: "Don't have an account? Sign up to CityScan",
            es: "¿No tienes una cuenta? Registrate a CityScan"
        }
    },
    signup: {
        titulo: {
            en: 'SIGN UP TO CITYSCAN',
            es: 'REGISTRARSE A CITYSCAN'
        },
        nombre: {
            en: 'First Name',
            es: 'Nombres'
        },
        error: {
            en: 'There was an error. Please try again later',
            es: 'Hubo un error. Por favor intentalo más tarde'
        },
        inseguro: {
            en: 'Tu contraseña debe contener una ',
            es: 'Hubo un error. Por favor intentalo más tarde'
        },
        apellido: {
            en: 'Last Name',
            es: 'Apellidos'
        },
        email: {
            en: 'Email',
            es: 'Correo Electronico'
        },
        confirmation: {
            en: 'We will send you a mail confirmation.',
            es: 'Te enviaremos un email de confirmación.'
        },
        password: {
            en: 'Password',
            es: 'Contraseña'
        },
        confirmPassword: {
            en: 'Confirm Password',
            es: 'Confirmar contraseña'
        },
        coinciden: {
            en: 'The passwords do not match',
            es: 'Las contraseñas no coinciden'
        },
        telefono: {
            en: 'Phone number',
            es: 'Número de telefono'
        },
        boton: {
            en: 'SIGN UP',
            es: 'REGISTRARSE'
        },
        cuenta: {
            en: 'Already have an account? Log in to CityScan',
            es: '¿Ya tienes una cuenta? Inicia sesión con CityScan'
        }
    },
    confirmedYourEmail: {
        title: {
            en: 'Confirm your account',
            es: 'Confirma tu cuenta'
        },
        description: {
            en: 'Confirm your account from the inbox of the email you entered to enjoy new tourist experiences around you',
            es: 'Confirma tu cuenta desde la bandeja de entrada del correo que ingresaste para disfrutar de nuevas experiencias turísticas alrededor de ti'
        },
        button: {
            en: 'Ready',
            es: 'Listo'
        }
    },
    accountConfirmed: {
        en: 'Your account was successfully confirmed',
        es: 'Tu cuenta fue confirmada satisfactoriamente'
    },
    password: {
        titulo: {
            en: 'FORGOT PASSWORD?',
            es: '¿OLVIDASTE TU CONTRASEÑA?'
        },
        email: {
            en: 'Email',
            es: 'Correo Electronico'
        },
        signal: {
            en: "If you forgot your password please enter your account's email address. You will receive an email with a link to reset your password",
            es: "Si olvidaste tu contraseña por favor escribe tu dirección de correo electrónico. Recibirás un email con un link para cambiar tu contraseña"
        },
        enviar: {
            en: 'SEND EMAIL',
            es: 'ENVIAR EMAIL'
        },
        cuenta: {
            en: "Don't have an account? Sign up to CityScan",
            es: '¿No tienes una cuenta? Registrate a CityScan'
        },
        error: {
            en: 'There was an error. Please try again later',
            es: 'Hubo un error. Por favor intentalo más tarde'
        },
    },
    passwordReset:{
        title: {
            en: 'Check your inbox',
            es: 'Revisa tu bandeja de entrada'
        },
        description: {
            en: 'We send you an email where you will find a step by step guide to recover your password',
            es: 'Te enviamos un correo donde encontrarás instrucciones para recuperar tu contraseña'
        },
        button: {
            en: 'Ready',
            es: 'Listo'
        }
    },
    changePassword: {
        titulo: {
            en: 'CHANGE PASSWORD',
            es: 'CAMBIAR CONTRASEÑA'
        },
        current: {
            en: 'Current password',
            es: 'Contraseña actual'
        },
        new: {
            en: 'New password',
            es: 'Nueva contraseña'
        },
        confirm: {
            en: 'Confirm password',
            es: 'Confirmar contraseña'
        },
        ok: {
            en: 'OK',
            es: 'LISTO'
        },
        forgot: {
            en: 'Forgot password? Remind me',
            es: '¿Olvidaste tu constraseña?'
        },
        sameError: {
            en: 'The passwords you entered are not the same',
            es: 'Las contraseñas que ingresaste no coinciden'
        },
        badPassword: {
            en: 'Your password must be a combination of letters and numbers',
            es: 'Tu contraseña debe ser una combinación de letras y números'
        }
    },
    dialogoErrorContrasenia: {
        titulo: {
            en: 'ERROR CHANGING YOUR PASSWORD',
            es: 'ERROR CAMBIANDO TU CONTRASEÑA'
        },
        descripcion: {
            en: 'There was a problem changing your password, please try again later',
            es: 'Hubo un problema al cambiar tu contraseña, inténtalo de nuevo más tarde'
        },
    },
    dialogoContrasenaCambiada: {
        titulo: {
            en: 'PASSWORD CHANGED SUCCESSFULLY',
            es: 'CONTRASEÑA CAMBIADA EXITOSAMENTE'
        },
        descripcion: {
            en: 'Your password was updated succesfully',
            es: 'Tu contraseña fue actualizada correctamente'
        },
    },
    perfil: {
        imagen: {
            en: 'Add a profile picture',
            es: 'Agregar una imagen de perfil'
        },
        guardar: {
            en: 'SAVE CHANGES',
            es: 'GUARDAR CAMBIOS'
        },
        editar: {
            en: 'EDIT PROFILE',
            es: 'EDITAR PERFIL'
        },
        nombres: {
            en: 'First name',
            es: 'Nombres'
        },
        apellidos: {
            en: 'Last name',
            es: 'Apellidos'
        },
        email: {
            en: 'E-mail',
            es: 'Correo'
        },
        descripction: {
            en: 'Description',
            es: 'Descripción'
        },
        phoneNumber: {
            en: 'Phone number',
            es: 'Número de telefono'
        },
        editarPayment: {
            en: 'Edit payment method',
            es: 'Editar método de pago'
        },
        agregarPayment: {
            en: 'Add payment method',
            es: 'Agregar método de pago'
        },
        cambiarContraseña: {
            en: 'Change password',
            es: 'Cambiar contraseña'
        },
        agendados: {
            en: 'TOURS I HAVE BOOKED',
            es: 'TOURS AGENDADOS'
        },
        sinToursAgendados: {
            en: 'You haven\'t booked any tour',
            es: 'No has agendado ningún tour'
        },
        explorarTours: {
            en: 'Explore tours',
            es: 'Explorar tours'
        },
        sobreTour: {
            en: 'ABOUT THIS TOUR',
            es: 'SOBRE ESTE TOUR'
        },
        likeados: {
            en: 'TOURS I LIKED',
            es: 'TOURS FAVORITOS'
        },
        sinLikedTours: {
            en: 'You haven\'t liked any tour',
            es: 'No te ha gustado ningún tour'
        },
        error: {
            en: 'An error has occurred, please try again later',
            es: 'Ha ocurrido un error, por favor intenetelo mas tarde'
        }
    }
}